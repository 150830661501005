import React from "react";

import chatDislikeIcon from "../../../assets/img/chatDislikeIcon.png";
import chatLikeIcon from "../../../assets/img/chatLikeIcon.png";
import { formatDate } from "utils/functions";
import { date_format } from "variables/date";

export const TABLE_COULMNS = [
  {
    title: "Chat ID",
    key: "id",
    get: (obj) => <span className="bold">{obj.id}</span>,
  },
  {
    title: "User ID",
    key: "user",
    get: (obj) => <span>{obj.user}</span>,
  },
  {
    title: "Likes",
    key: "reactions_like_count",
    get: (obj) => (
      <div className="reaction">
        <span className="reaction-value"> {obj.reactions_like_count}</span>{" "}
        <img className="reaction-icon" src={chatLikeIcon} />
      </div>
    ),
  },
  {
    title: "Dislikes",
    key: "reactions_dislike_count",
    get: (obj) => (
      <div className="reaction">
        <span className="reaction-value"> {obj.reactions_dislike_count}</span>{" "}
        <img className="reaction-icon" src={chatDislikeIcon} />
      </div>
    ),
  },
  {
    title: "Messages",
    key: "messages_count",
    get: (obj) => <span>{obj.messages_count}</span>,
  },
  {
    title: "Date",
    key: "created_at",
    get: (obj) => <span>{formatDate(obj.created_at, date_format.short)}</span>,
  },
  {
    title: "Time",
    key: "created_at",
    get: (obj) => <span>{formatDate(obj.created_at, date_format.time)}</span>,
  },
];
