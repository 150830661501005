import { all, call, put, takeLatest } from "redux-saga/effects";

import XHR from "../../utils/XHR";

import config from "config/app";
import { toast } from "react-hot-toast";

//Types
const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST";
const GET_NOTIFICATION_PROCESS = "GET_NOTIFICATION_PROCESS";

const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST";
const DELETE_NOTIFICATION_PROCESS = "DELETE_NOTIFICATION_PROCESS";

const ADMIN_NOTIFICATION_REQUEST = "ADMIN_NOTIFICATION_REQUEST";
const ADMIN_NOTIFICATION_SUCCESS = "ADMIN_NOTIFICATION_SUCCESS";
const ADMIN_NOTIFICATION_FALUIRE = "ADMIN_NOTIFICATION_FALUIRE";

const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
const UPDATE_NOTIFICATION_FALUIRE = "UPDATE_NOTIFICATION_FALUIRE";

//Actions
export const getNotificationRequest = (data) => ({
  type: GET_NOTIFICATION_REQUEST,
  data,
});

export const getNotificationProcess = (data) => ({
  type: GET_NOTIFICATION_PROCESS,
  data,
});

export const deleteNotificationRequest = (data, toggle) => ({
  type: DELETE_NOTIFICATION_REQUEST,
  data,
  toggle,
});

export const deleteNotificationProcess = (data, toggle) => ({
  type: DELETE_NOTIFICATION_PROCESS,
  data,
  toggle,
});

export const adminNotificationRequest = (data, toggle) => ({
  type: ADMIN_NOTIFICATION_REQUEST,
  data,
  toggle,
});

export const adminNotificationSuccess = (data, toggle) => ({
  type: ADMIN_NOTIFICATION_SUCCESS,
  data,
  toggle,
});
export const adminNotificationFaluire = (data) => ({
  type: ADMIN_NOTIFICATION_FALUIRE,
  data,
});

export const updateNotificationRequest = (data, toggle) => ({
  type: UPDATE_NOTIFICATION_REQUEST,
  data,
  toggle,
});

export const updateNotificationSuccess = (data, toggle) => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  data,
  toggle,
});
export const updateNotificationFaluire = (data) => ({
  type: UPDATE_NOTIFICATION_FALUIRE,
  data,
});

const initialState = {
  requesting: false,
  createRequesting: false,
  addedNotification: false,
  deletedNotification: false,
  updateRequesting: false,
  deleteRequesting: false,
  adminNotification: false,
  notification: false,
  updatedNotifications: false,
  error: false,
};

//Reducer
export const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case GET_NOTIFICATION_PROCESS:
      return {
        ...state,
        requesting: false,
        adminNotification: action.data,
      };

    case DELETE_NOTIFICATION_REQUEST:
      return {
        ...state,
        deleteRequesting: true,
      };
    case DELETE_NOTIFICATION_PROCESS:
      return {
        ...state,
        deleteRequesting: false,
      };

    case ADMIN_NOTIFICATION_REQUEST:
      return {
        ...state,
        createRequesting: true,
      };
    case ADMIN_NOTIFICATION_SUCCESS:
      return {
        ...state,
        createRequesting: false,
        notification: action.data,
      };
    case ADMIN_NOTIFICATION_FALUIRE:
      return {
        ...state,
        createRequesting: false,
        error: action.data,
      };

    case UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        updateRequesting: true,
      };
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        updateRequesting: false,
        updatedNotifications: action.data,
      };
    case UPDATE_NOTIFICATION_FALUIRE:
      return {
        ...state,
        updateRequesting: false,
        error: action.data,
      };
    default:
      return state;
  }
};

//Saga
async function getNotificationAPI(data) {
  const URL = `${config.baseApiUrl}/api/v1/admin_notifications/?limit=10&offset=${data.offset}`;
  const prc_authToken = await localStorage.getItem("prc_authToken");
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "GET",
  };
  return XHR(URL, options);
}

function* getNotificationFunction({ data }) {
  try {
    const response = yield call(getNotificationAPI, data);
    yield put(getNotificationProcess(response.data));
  } catch (e) {
    yield put(getNotificationProcess(false));
  }
}

async function deleteNotificationAPI(data) {
  const URL = `${config.baseApiUrl}/api/v1/admin_notifications/${data}/`;
  const prc_authToken = await localStorage.getItem("prc_authToken");
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "DELETE",
  };
  return XHR(URL, options);
}

function* deleteNotificationFunction({ data, toggle }) {
  try {
    const response = yield call(deleteNotificationAPI, data);
    yield put(deleteNotificationProcess(response));
    yield put(getNotificationRequest({ offset: 0 }));
    toggle(false);
    toast.success("Delete notification successfully");
  } catch (e) {
    yield put(deleteNotificationProcess(false));
  }
}

async function adminNotificationAPI(data, toggle) {
  const URL = `${config.baseApiUrl}/api/v1/admin_notifications/`;
  const prc_authToken = await localStorage.getItem("prc_authToken");

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "POST",
    data,
  };
  return XHR(URL, options);
}

function* adminNotificationFunction({ data, toggle }) {
  try {
    const response = yield call(adminNotificationAPI, data);
    yield put(adminNotificationSuccess(response.data));
    yield put(getNotificationRequest({ offset: 0 }));
    toggle(false);
    toast.success("Notification created successfully");
  } catch (e) {
    let err = e?.response?.data?.non_field_errors
      ? e.response.data.non_field_errors
      : e?.response?.data["detail"]
      ? e.response.data["detail"].toString()
      : e?.response?.data["category"]
      ? e.response.data["category"].toString()
      : e.response.data["logo"]
      ? "No logo was submitted."
      : "Something went wrong.";
    if (e.message === "Network Error") {
      toast.error("Connection Error");
      yield put(adminNotificationFaluire("Connection Error"));
    } else {
      toast.error(err);
      yield put(adminNotificationFaluire(err));
    }
  }
}

async function updateNotificationAPI(data) {
  const URL = `${config.baseApiUrl}/api/v1/admin_notifications/${data.id}/`;
  const prc_authToken = await localStorage.getItem("prc_authToken");
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "PATCH",
    data: data.form,
  };
  return XHR(URL, options);
}

function* updateNotificationFunction({ data, toggle }) {
  try {
    const response = yield call(updateNotificationAPI, data);
    yield put(updateNotificationSuccess(response?.data));
    yield put(getNotificationRequest({ offset: 0 }));
    toggle(false);
    toast.success("Notification updated successfully");
  } catch (e) {
    let err = e?.response?.data;
    if (e.message === "Network Error") {
      toast.error("Connection Error");
      yield put(updateNotificationFaluire("Connection Error"));
    } else {
      toast.error(err);
      yield put(updateNotificationFaluire(err));
    }
  }
}

export default all([
  takeLatest(GET_NOTIFICATION_REQUEST, getNotificationFunction),
  takeLatest(ADMIN_NOTIFICATION_REQUEST, adminNotificationFunction),
  takeLatest(DELETE_NOTIFICATION_REQUEST, deleteNotificationFunction),
  takeLatest(UPDATE_NOTIFICATION_REQUEST, updateNotificationFunction),
]);
