import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChatContinueConversationState, ChatWelcomeState } from "./components";
import { constants } from "./redux";
import { constants as chatConstants } from "../Chat/redux";

import "./style.scss";

const ChatWidget = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const state = useSelector((state) => state.VisualChatWidgetReducer);

  const handleChatOpen = useCallback(() => {
    history.push(`/user/chat/${state?.last_created_chat.id}`);
  }, [state?.last_created_chat]);

  const handleStartConversation = useCallback(
    (message) => {
      dispatch({
        type: chatConstants.CREATE_CHAT,
        cbSuccess: (chat) =>
          history.push({
            pathname: `/user/chat/${chat.id}`,
            state: { message },
          }),
      });
    },
    [dispatch]
  );

  const WidgetContent = useMemo(() => {
    if (state?.requesting) {
      return <div className="loading-state" />;
    }

    if (state?.last_created_chat) {
      return <ChatContinueConversationState onOpenChat={handleChatOpen} chat={state.last_created_chat} />;
    }

    return <ChatWelcomeState onSubmit={handleStartConversation} />;
  }, [handleChatOpen, handleStartConversation, state?.requesting, state?.last_created_chat]);

  useEffect(() => {
    dispatch({
      type: constants.GET_LAST_CREATED_CHAT,
    });
  }, [dispatch]);

  return <div className="chat-widget-wrapper">{WidgetContent}</div>;
};

export default ChatWidget;
