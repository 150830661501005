import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getChatRequest, getChatRequestSuccess } from "../redux/actions";

export const useGetChatInfo = (id) => {
  const dispatch = useDispatch();

  const { chat } = useSelector((state) => state.ChatDetails);

  const messages = chat?.messages || [];

  useEffect(() => {
    if (id) {
      dispatch(getChatRequest(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(getChatRequestSuccess(null));
    };
  }, [dispatch]);

  return {
    chat,
    messages,
  };
};
