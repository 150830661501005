import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-hot-toast";

import config from "config/app";
import XHR from "utils/XHR";

import { getChatRequestSuccess } from "./actions";
import { GET_CHAT_REQUEST } from "./types";

async function getChatAPI(chatId) {
  const accessToken = localStorage.getItem("prc_authToken");

  const URL = `${config.baseApiUrl}/api/v1/admin_chat/${chatId}/`;

  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json",
    },
    method: "GET",
  };

  return XHR(URL, options);
}

function* getChat({ data }) {
  try {
    const response = yield call(getChatAPI, data);
    yield put(getChatRequestSuccess(response?.data?.chat || null));
  } catch (e) {
    toast.error("Failed to get chat details");
  }
}

export default all([takeLatest(GET_CHAT_REQUEST, getChat)]);
