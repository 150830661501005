import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "utils/functions";
import { date_format } from "variables";

import { exportAllChatsRequest } from "../redux/actions";

const useExport = (data, userId) => {
  const dispatch = useDispatch();
  const { isExporting } = useSelector((state) => state.ChatsList);

  const handleExport = () => {
    let fileName = "Chats";

    if (userId) {
      fileName += `_${userId}`;
    } else {
      fileName += data.search ? "_SearchResults" : "_FullList";
    }

    fileName += `_${formatDate(new Date(), date_format.export)}.csv`;

    dispatch(exportAllChatsRequest({ ...data, fileName }));
  };

  return { handleExport, isExporting };
};

export default useExport;
