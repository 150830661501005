import { all, call, put, takeLatest } from "redux-saga/effects";

import XHR from "../../utils/XHR";

import config from "config/app";
import { toast } from "react-hot-toast";

//Types
const GET_ADMIN_FEEDBACK_REQUEST = "GET_ADMIN_FEEDBACK_REQUEST";
const GET_ADMIN_FEEDBACK_PROCESS = "GET_ADMIN_FEEDBACK_PROCESS";

const REPLY_FEEDBACK_REQUEST = "REPLY_FEEDBACK_REQUEST";
const REPLY_FEEDBACK_SUCCESS = "REPLY_FEEDBACK_SUCCESS";
const REPLY_FEEDBACK_FALUIRE = " REPLY_FEEDBACK_FALUIRE";

//Actions
export const getAdminFeedbackRequest = (data) => ({
  type: GET_ADMIN_FEEDBACK_REQUEST,
  data,
});

export const getAdminFeedbackProcess = (data) => ({
  type: GET_ADMIN_FEEDBACK_PROCESS,
  data,
});

export const replyFeedbackRequest = (data, feedbackModal) => ({
  type: REPLY_FEEDBACK_REQUEST,
  data,
  feedbackModal,
});

export const replyFeedbackSuccess = (data) => ({
  type: REPLY_FEEDBACK_SUCCESS,
  data,
});
export const replyFeedbackFaluire = (data) => ({
  type: REPLY_FEEDBACK_FALUIRE,
  data,
});

const initialState = {
  requesting: false,
  adminFeedback: false,
  replyFeedback: false,
  error: false,
};

//Reducer
export const getAdminFeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_FEEDBACK_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case GET_ADMIN_FEEDBACK_PROCESS:
      return {
        ...state,
        requesting: false,
        adminFeedback: action.data,
      };
    case REPLY_FEEDBACK_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case REPLY_FEEDBACK_SUCCESS:
      return {
        ...state,
        requesting: false,
        replyFeedback: action.data,
      };
    case REPLY_FEEDBACK_FALUIRE:
      return {
        ...state,
        requesting: false,
        error: action.data,
      };
    default:
      return state;
  }
};

//Saga
async function getAdminFeedbackAPI(data) {
  const URL = `${config.baseApiUrl}/api/v1/user_feedback/?limit=10&offset=${data.offset}`;
  const accessToken = await localStorage.getItem("prc_authToken");
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${accessToken}`,
    },
    method: "GET",
  };
  return XHR(URL, options);
}

function* getAdminFeedbackFunction({ data }) {
  try {
    const response = yield call(getAdminFeedbackAPI, data);
    yield put(getAdminFeedbackProcess(response.data));
  } catch (e) {
    yield put(getAdminFeedbackProcess(false));
  }
}

async function replyFeedbackAPI(data) {
  const URL = `${config.baseApiUrl}/api/v1/admin_feedback/`;
  const prc_authToken = await localStorage.getItem("prc_authToken");
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "POST",
    data,
  };
  return XHR(URL, options);
}

function* replyFeedbackFunction({ data, feedbackModal }) {
  try {
    const response = yield call(replyFeedbackAPI, data);

    yield put(replyFeedbackSuccess(response.data));
    toast.success(`Replied Success !`);
    feedbackModal();
  } catch (e) {
    if (e.message === "Network Error") {
      toast.error("Connection Error");
      yield put(replyFeedbackFaluire("Connection Error"));
    } else {
      let err = e?.response?.data?.non_field_errors
        ? e.response.data.non_field_errors
        : e?.response?.data["detail"]
        ? e.response.data["detail"]
        : e?.response?.data["category"]
        ? e.response.data["category"][0]
          ? e.response.data["category"][0]
          : e.response.data["category"]
        : e.response.data["logo"]
        ? "No logo was submitted."
        : "Something went wrong.";
      toast.error(err);
      yield put(replyFeedbackFaluire(err));
    }
  }
}

export default all([
  takeLatest(GET_ADMIN_FEEDBACK_REQUEST, getAdminFeedbackFunction),
  takeLatest(REPLY_FEEDBACK_REQUEST, replyFeedbackFunction),
]);
