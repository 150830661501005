import { all, call, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";

import toast from "react-hot-toast";
// config
import config from "config/app";

// utils
import XHR from "utils/XHR";

// types
import { RESET_PASSWORD_REQUEST } from "./types";

// actions
import { resetEndProcess } from "./actions";

function resetPasswordAPI(data) {
  const URL = `${config.baseApiUrl}/rest-auth/password/reset/confirm/`;
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    data,
  };

  return XHR(URL, options);
}

function* resetPassword({ data }) {
  try {
    const response = yield call(resetPasswordAPI, data);
    yield put(resetEndProcess());
    toast.success(response.data.detail);
    yield put(push({ pathname: "/auth/login" }));
  } catch (e) {
    console.log("e", e.response.data);
    const error = "Failed to reset password, please try again";
    if (e.message === "Network Error") {
      toast.error("Connection Error");
      yield put(resetEndProcess("Connection Error"));
    } else if (e?.response?.data?.new_password2 || e?.response?.data?.new_password1) {
      Object.keys(e.response.data).forEach((key) => toast.error(e.response.data[key].toString()));
      yield put(resetEndProcess({}));
    } else if (e?.response?.data?.token) {
      console.log("e.response.data", e.response.data);
      if (e.response.data.token) {
        if (e.response.data.token?.length && e.response.data.token[0] === "Invalid value") {
          toast.error("Invalid token value");
        } else {
          toast.error(error);
        }
      }
      yield put(resetEndProcess({}));
    } else {
      toast.error(error);
      yield put(resetEndProcess(error));
    }
  }
}

export default all([takeLatest(RESET_PASSWORD_REQUEST, resetPassword)]);
