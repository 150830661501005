import { GET_CHAT_REQUEST, GET_CHAT_REQUEST_FALUIRE, GET_CHAT_REQUEST_SUCCESS } from "./types";

export const getChatRequest = (data) => ({
  type: GET_CHAT_REQUEST,
  data,
});

export const getChatRequestSuccess = (data) => ({
  type: GET_CHAT_REQUEST_SUCCESS,
  data,
});
