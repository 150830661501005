import React, { useEffect } from "react";
import { Switch, Router, Redirect } from "react-router-dom";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import UserLayout from "layouts/User";
import RouteGuard from "./RouterGuard";
import { history } from "./Redux/store";
import { getFirebaseToken } from "./firebase";
import { onForegroundMessage } from "./firebase";
import { useDispatch } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { constants } from "WebVisual/Components/Notifications/redux";

const App = () => {
  const accessToken = localStorage.getItem("prc_authToken");

  const ToastifyNotification = ({ title, body }) => (
    <div className="push-notification">
      <h2 className="push-notification-title">{title}</h2>
      <p className="push-notification-text">{body}</p>
    </div>
  );

  const dispatch = useDispatch();

  const handleGetFirebaseToken = () => {
    try {
      getFirebaseToken()
        .then((firebaseToken) => {
          if (firebaseToken) {
            dispatch({
              type: constants.REGISTER_DEVICE,
              payload: {
                registration_id: firebaseToken,
                cloud_message_type: "FCM",
              },
            });
          }
        })
        .catch((err) => console.log("An error occured while retrieving firebase token. ", err));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    onForegroundMessage()
      .then((payload) => {
        const {
          notification: { title, body },
        } = payload;

        toast(<ToastifyNotification title={title} body={body} />);
      })
      .catch((err) => console.log("An error occured while retrieving foreground message. ", err));
    accessToken && history.location.pathname.split("/", 3)[1] === "user" && handleGetFirebaseToken();
  }, [accessToken]);

  return (
    <Router history={history}>
      <Switch>
        <RouteGuard path="/auth" component={(props) => <AuthLayout {...props} />} />
        <RouteGuard path="/admin" component={(props) => <AdminLayout {...props} />} isProtected />
        <RouteGuard path="/user" component={(props) => <UserLayout {...props} />} isProtected />
        {accessToken ? <Redirect to="/user" /> : <Redirect to="/auth/login" />}
      </Switch>
      <ToastContainer hideProgressBar />
    </Router>
  );
};

export default App;
