import moment from "moment";
import { toast } from "react-hot-toast";

import { FIND_UDERSCORE_REG_EXP, REMOVE_FILE_EXTENSION_REG_EXP } from "./regExp";

export const getState = (state) => {
  const keys = Object.keys(state);
  const values = Object.values(state);
  let data = {};
  keys.forEach((item, i) => {
    data = {
      ...data,
      [item]: values[i].value,
    };
  });
  return data;
};

export const characterEllipsis = (string, characters = 2) => {
  if (string.length > characters * 2) {
    return string.substr(0, characters) + "***" + string.substr(string.length - characters, string.length);
  }
  return string;
};

export const nonAdminErrorMessage = (message) => {
  if (message === "Your are not a admin") {
    toast.error("No data available for non admin user");
  } else {
    toast.error("Request failed");
  }
};

export const Logout = async () => {
  await localStorage.clear();
  window.location.reload();
};

export const formatDate = (date, format) => {
  if (!date) return "";

  return moment(date).format(format);
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const showSuccessToast = (message) => {
  toast.success(message);
};

export const getLastPartOfUrl = (url) => {
  const parts = url.split("/");
  return parts[parts.length - 1];
};

export const getFileName = (filePath) => {
  const fileName = getLastPartOfUrl(filePath);

  const fileNameWithoutExtension = (fileName || "").replace(REMOVE_FILE_EXTENSION_REG_EXP, "");

  return (fileNameWithoutExtension || "").replace(FIND_UDERSCORE_REG_EXP, " ");
};

export const openInNewTab = (url) => {
  window.open(url, "_blank");
};

export const getFrotendUrl = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

export const getArticleUrlBySourceUrl = (sourceUrl) => {
  const url = `${getFrotendUrl()}/user/home?source=${encodeURIComponent(getLastPartOfUrl(sourceUrl))}`;

  return url;
};

export const calculateHoursDifference = (date1, date2) => {
  // Get the difference in milliseconds
  const diffInMs = Math.abs(date2 - date1);

  // Convert milliseconds to hours
  const diffInHours = diffInMs / (1000 * 60 * 60);

  return diffInHours;
};

export function downloadFile(data, fileName) {
  try {
    const blob = new Blob([data]);

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");

    anchor.download = fileName;
    anchor.href = url;

    anchor.click();
  } catch (error) {
    throw new Error(error);
  }
}

export function getUniqueRandomItems(array, numItems) {
  // Create a Set to store unique random indices
  const uniqueIndices = new Set();

  // Continue until we have the desired number of unique indices
  while (uniqueIndices.size < numItems) {
    const randomIndex = Math.floor(Math.random() * array.length);
    uniqueIndices.add(randomIndex); // Set automatically ensures uniqueness
  }

  // Map unique indices to their corresponding items in the original array
  return Array.from(uniqueIndices).map((index) => array[index]);
}
