import { all, call, put, takeLatest, select } from "redux-saga/effects";

import XHR from "../../../utils/XHR";

import config from "config/app";
import { toast } from "react-hot-toast";

export const getFavouritesState = (state) => state.VisualFavouritesReducer.favourites;

const TYPE = "WEBVISUAL_FAVOURITES_";
export const constants = {
  GET_FAVOURITES: `${TYPE}GET_FAVOURITES`,
  GET_FAVOURITES_SUCCESS: `${TYPE}GET_FAVOURITES_SUCCESS`,
  RESET_REQUEST: `${TYPE}RESET_REQUEST`,
  ADD_FAVOURITE: `${TYPE}ADD_FAVOURITE`,
  DATE_FILETER: `${TYPE}DATE_FILETER`,
};

const initialState = {
  requesting: false,
  favourites: [],
  count: 0,
};

export const VisualFavouritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.DATE_FILETER:
    case constants.GET_FAVOURITES:
      return {
        ...state,
        requesting: true,
      };
    case constants.GET_FAVOURITES_SUCCESS:
      return {
        ...state,
        favourites: action.response,
        count: action.count,
        requesting: false,
      };

    case constants.RESET_REQUEST:
      return {
        ...state,
        requesting: false,
      };

    default:
      return state;
  }
};

async function getFavouritesAPI(offset) {
  const URL = `${config.baseApiUrl}/api/v1/get_all_favorite/?limit=10&offset=${offset}`;
  const prc_authToken = localStorage.getItem("prc_authToken");
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "GET",
  };
  return XHR(URL, options);
}

function* getFavourites({ offset }) {
  try {
    const response = yield call(getFavouritesAPI, offset);
    yield put({
      type: constants.GET_FAVOURITES_SUCCESS,
      response: response.data.results,
      count: response.data.count,
    });
  } catch (e) {
    yield put({ type: constants.RESET_REQUEST });
    toast.error("Failed to get favourites");
  }
}

async function addFavouriteAPI(id) {
  const URL = `${config.baseApiUrl}/api/v1/article/${id}/add_favorite/`;
  const prc_authToken = localStorage.getItem("prc_authToken");
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "GET",
  };
  return XHR(URL, options);
}

function* addFavourite({ id, offset }) {
  try {
    yield call(addFavouriteAPI, id);
    const favourites = yield select(getFavouritesState);
    const updatedFavourites = favourites.map((favourite) => {
      if (favourite.id === id) {
        return { ...favourite, is_favorite: !favourite.is_favorite };
      }
      return favourite;
    });
    yield put({
      type: constants.GET_FAVOURITES_SUCCESS,
      response: updatedFavourites,
      count: updatedFavourites.length,
    });

    yield put({ type: constants.GET_FAVOURITES, offset });
  } catch (e) {
    toast.error("Failed to add favourite");
  }
}

async function dateFilterAPI(params) {
  const URL = `${config.baseApiUrl}/api/v1/article/filters/?is_favorite=True&${params}`;
  const prc_authToken = localStorage.getItem("prc_authToken");
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "GET",
  };
  return XHR(URL, options);
}

function* dateFilter({ params }) {
  try {
    const response = yield call(dateFilterAPI, params);
    yield put({
      type: constants.GET_FAVOURITES_SUCCESS,
      response: response.data,
    });
  } catch (e) {
    yield put({ type: constants.RESET_REQUEST });
    toast.error("Failed to get data for selected date.");
  }
}

export default all([
  takeLatest(constants.GET_FAVOURITES, getFavourites),
  takeLatest(constants.ADD_FAVOURITE, addFavourite),
  takeLatest(constants.DATE_FILETER, dateFilter),
]);
