import { useHistory, useLocation } from "react-router-dom";

export const useHandleChatGoBack = (handleClearState) => {
  const location = useLocation();
  const history = useHistory();

  const handleBackClick = () => {
    const isFromChat = location.state?.isFromChat;

    if (history.location.key && !isFromChat) {
      handleClearState();
      history.goBack();
    } else {
      history.push("/user/home");
    }
  };

  return {
    handleBackClick,
  };
};
