import React from "react";
import { useParams, useHistory } from "react-router-dom";

import { useExport } from "Containers/ChatsList/hooks";
import { ChatMessages } from "WebVisual/Components/Chat/components";

import { ChatDetailsHeader } from "./components";
import { useGetChatInfo } from "./hooks";

import "./style.scss";

const ChatDetails = () => {
  const { id } = useParams();
  const history = useHistory();

  const { chat, messages } = useGetChatInfo(id);
  const { handleExport, isExporting } = useExport({ search: chat?.id }, chat?.user);

  const handleBackBth = () => {
    history.push("/admin/list-of-chats");
  };

  return (
    <div className="admin-chat">
      <ChatDetailsHeader chat={chat} onBackClick={handleBackBth} onExport={handleExport} isExporting={isExporting} />
      <div className="chat-content">
        <ChatMessages isAdmin={true} isReversed={true} messages={messages} />
      </div>
    </div>
  );
};

export default ChatDetails;
