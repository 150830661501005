export const GET_ALL_CHATS_REQUEST = "GET_ALL_CHATS_REQUEST";
export const GET_ALL_CHATS_SUCCESS = "GET_ALL_CHATS_SUCCESS";
export const GET_ALL_CHATS_FALUIRE = "GET_ALL_CHATS_FALUIRE";

export const CHANGE_CHATS_FILTER_REQUEST = "CHANGE_CHATS_FILTER_REQUEST";
export const CLEAR_CHATS_FILTER_REQUEST = "CLEAR_CHATS_FILTER_REQUEST";

export const EXPORT_ALL_CHATS_REQUEST = "EXPORT_ALL_CHATS_REQUEST";
export const EXPORT_ALL_CHATS_SUCCESS = "EXPORT_ALL_CHATS_SUCCESS";
export const EXPORT_ALL_CHATS_FALUIRE = "EXPORT_ALL_CHATS_FALUIRE";
