import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import "./style.scss";
// reactstrap components
import Images from "utils/Images";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { constants } from "../ArticleCard/redux";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import ReactToPrint from "react-to-print";
import printJS from "print-js";
import { toast } from "react-hot-toast";
import { updateAnalyticsAction } from "../Settings/redux";
import PdfViewer from "../PdfViewer";

const ArticleDetail = ({ item, setActive }) => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector((state) => state.VisualHomeReducer);
  const [isToggle, setIsToggle] = useState(false);
  const [modal, setModal] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [actionType, setActionType] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  const pdfLink = item.document;

  useEffect(() => {
    if (state && state.pdfData) {
      const fileURL = window.URL.createObjectURL(new Blob([state.pdfData]));
      if (actionType === "download") {
        const alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `Article_ID_${item.id}_${item.category}.pdf`;
        alink.click();
      } else if (actionType === "print") {
        printJS(fileURL);
      }
      setActionType("");
      dispatch({ type: constants.RESET_DATA });
    }
  }, [state.pdfData, actionType]);

  const onButtonClick = (actionType) => {
    setActionType(actionType);
    if (pdfLink) {
      dispatch({
        type: constants.DOWNLOAD_PDF_REQUEST,
        data: item,
        actionType,
      });
    } else {
      toast.error(`Article must have document attached to ${actionType}.`);
    }
  };

  const [isCopied, setIsCopied] = useState(false);
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const copy = (text) => {
    copyTextToClipboard(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onShareLink = (article, id, shared) => {
    dispatch(updateAnalyticsAction(article, id, shared));
  };

  const addFavourite = (state) => {
    dispatch({
      type: constants.ADD_FAVOURITE,
      id: item.id,
      articleDetails: true,
      state,
    });
  };

  const handleBackClick = () => {
    setActive(false);
    history.push("/user/home");

    if (source) {
      dispatch({
        type: constants.GET_ARTICLES,
        offset: 0,
      });
    }
  };

  return (
    <div>
      <Row className="justify-content-between topBtnWrraper">
        <div>
          <Button className="backBtn" onClick={handleBackClick}>
            <img className="backIcon-img" src={Images.backIcon} alt="" />
          </Button>
        </div>

        <div className="dropdownWrraper">
          <div className="">
            <Dropdown isOpen={isToggle} toggle={() => setIsToggle(!isToggle)}>
              <DropdownToggle
                aria-haspopup={true}
                color="default"
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                nav
                className="dropdownTogle"
              >
                <Button onClick={() => setIsToggle(!isToggle)} className=" text-capitalize">
                  . . .
                </Button>
              </DropdownToggle>
              <DropdownMenu persist aria-labelledby="navbarDropdownMenuLink" right>
                <DropdownItem className="firstItem" onClick={() => addFavourite(!item.is_favorite)}>
                  <img src={item.is_favorite ? Images.favIcon : Images.unLikeIcon} alt="" />
                  <p>Mark as favourite </p>
                </DropdownItem>

                <DropdownItem className="secondItem" onClick={() => onButtonClick("download")}>
                  <img src={Images.downloadIcon} alt="" />
                  <p>Download </p>
                </DropdownItem>

                <DropdownItem className="thirdItem" onClick={() => setModal(true)}>
                  <img src={Images.shareIcon} alt="" />
                  <p>Share </p>
                </DropdownItem>
                <DropdownItem className="fourthItem" onClick={() => onButtonClick("print")}>
                  <img src={Images.printIcon} alt="" />
                  <ReactToPrint trigger={() => <p>Print</p>} />
                </DropdownItem>
                <DropdownItem onClick={() => setActive(false)} className="fifthhItem">
                  <img src={Images.closeIcon} alt="" />
                  <p>Close </p>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </Row>
      <Row className="articleDesign">
        <Col lg="12" className="">
          <Card body className="p-0">
            <CardHeader className="cardHeader">
              <img src={Images.cardLogo} />
            </CardHeader>
            <CardTitle className="cardTitle">
              <p>{item.title}</p>
            </CardTitle>
            <CardText>
              <Row className="cardData">
                <p className="categoryText">Category:</p>
                <p className="categoryType">{item.category}</p>
              </Row>
              <p className="dateDesign">{moment(item.date).format("MMMM, DD, YYYY")}</p>
            </CardText>
          </Card>
        </Col>
        <Col md="12">
          <div
            style={{
              width: "100%",
              height: "850px",
              padding: 0,
              overflow: "hidden",
              position: "relative",
            }}
          >
            <PdfViewer pdfLink={pdfLink} />
          </div>
          {/* <Card className="cardStyled">
            <Row className="cardDetails-wrapper">
              <Col md="12" className="articleHeadings">
                <div>
                  <p className="firstHead">Macrow Notes</p>
                  <p className="secondHead">
                    Macrow Update: RMB Outlook for 2020
                  </p>
                  <p className="thirdHead">
                    Political and Cyclical Factors Pushing for Stronger RMB
                  </p>
                  <p className="dateStyle">{moment(item.date).format('MMMM, DD, YYYY')}</p>
                </div>
                <div>
                  <img src={Images.cardLogo} alt="" />
                </div>
              </Col>
            </Row>

            <Row className="cardDataList">
              <Col md="12" className="articledata">
                <div>
                  <ul>
                    <li>
                      {item.short_summary}
                    </li>
                  </ul>
                </div>
                <div>
                  <ul>
                    {item.short_summary}
                  </ul>
                </div>
              </Col>
            </Row>
          </Card> */}
        </Col>
      </Row>
      <Modal className="shareModalWrapper" isOpen={modal} toggle={""} centered={true}>
        <div className="modalHeader">
          <p>Share Via</p> <img onClick={() => setModal(!modal)} src={Images.closeIcon} alt="cross-icon" />
        </div>
        <div className="socialIconsWrapper">
          <div onClick={() => onShareLink("article", item.id, "shared")} className="fbBox">
            <FacebookShareButton url={item.document}>
              <FacebookIcon logoFillColor="white" round size={50} />
            </FacebookShareButton>
            <p>Facebook</p>
          </div>
          <div className="twitterBox" onClick={() => onShareLink("article", item.id, "shared")}>
            <TwitterShareButton url={item.document}>
              <TwitterIcon logoFillColor="white" round size={50} />
            </TwitterShareButton>
            <p>Twitter</p>
          </div>
          <div className="linkedInBox" onClick={() => onShareLink("article", item.id, "shared")}>
            <LinkedinShareButton url={item.document}>
              <LinkedinIcon logoFillColor="white" round size={50} />
            </LinkedinShareButton>
            <p>LinkedIn</p>
          </div>
          <div className="gmailBox" onClick={() => onShareLink("article", item.id, "shared")}>
            <EmailShareButton url={item.document}>
              <EmailIcon logoFillColor="white" round size={50} />
            </EmailShareButton>
            <p>Email</p>
          </div>
          <div className="gmailBox" onClick={() => onShareLink("article", item.id, "shared")}>
            <WhatsappShareButton url={item.document}>
              <WhatsappIcon logoFillColor="white" round size={50} />
            </WhatsappShareButton>
          </div>
        </div>
        <ModalBody className="modalBody">
          <div className="urlBox">
            <input type="text" name="" value={item.document} />
          </div>

          <div className="imgBox" style={{ background: isCopied ? "pink" : "" }} onClick={() => copy(item.document)}>
            <img src={Images.copyIcon} alt="" />
          </div>
        </ModalBody>
      </Modal>

      <Modal className="printModalWrapper" isOpen={printModal} toggle={""} centered={true}>
        <div className="modalHeader">
          <p>Print</p> <img onClick={() => setPrintModal(!printModal)} src={Images.closeIcon} alt="cross-icon" />
        </div>

        <div className="copiesData">
          <p>Number of Copies</p>
          <div className="noOfCopies">
            <p>2</p>
          </div>
        </div>

        <div className="hrStyle">
          <hr />
          <p className="totalPages">Total: 34 Page</p>
          <p>Print Options</p>
        </div>

        <div className="printerWrapper">
          <div className="printerStyle">
            <img src={Images.printerIcon} alt="" />
            <p>Hp Pagewider</p>
            <p className="conectedStatus">Connected</p>
          </div>
          <div className="printerStyle">
            <img src={Images.printerIcon} alt="" />
            <p>Hp Lazer Jet</p>
            <p className="conectedStatus">Connected</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ArticleDetail;
