import { all, call, put, takeLatest } from "redux-saga/effects";

import XHR from "../../../utils/XHR";

import config from "config/app";
import { toast } from "react-hot-toast";

const TYPE = "WEBVISUAL_ARTICLES_";
export const constants = {
  GET_NOTIFICATION: `${TYPE}GET_NOTIFICATION`,
  GET_NOTIFICATION_SUCCESS: `${TYPE}GET_NOTIFICATION_SUCCESS`,
  RESET_REQUEST: `${TYPE}RESET_REQUEST`,
  FILTER_NOTIFICATION: `${TYPE}FILTER_NOTIFICATION`,
  MARK_AS_READ_NOTIFICATION: `${TYPE}MARK_AS_READ_NOTIFICATION`,
  REGISTER_DEVICE: `${TYPE}REGISTER_DEVICE`,
};

const initialState = {
  requesting: false,
  notifications: [],
};

export const VisualNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    // case constants.MARK_AS_READ_NOTIFICATION:
    case constants.FILTER_NOTIFICATION:
    case constants.GET_NOTIFICATION:
      return {
        ...state,
        requesting: true,
      };
    case constants.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.response,
        requesting: false,
      };

    case constants.RESET_REQUEST:
      return {
        ...state,
        requesting: false,
      };

    default:
      return state;
  }
};

async function getNotificationAPI() {
  const URL = `${config.baseApiUrl}/modules/fcmnotifications/notification/`;
  const prc_authToken = localStorage.getItem("prc_authToken");
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "GET",
  };
  return XHR(URL, options);
}

function* getNotification() {
  try {
    const response = yield call(getNotificationAPI);
    yield put({
      type: constants.GET_NOTIFICATION_SUCCESS,
      response: response.data,
    });
  } catch (e) {
    toast.error("Failed to get notification");
    yield put({ type: constants.RESET_REQUEST });
  }
}

async function filterNotificationAPI(params) {
  const URL = `${config.baseApiUrl}/api/v1/user_notifications/${params}`;
  const prc_authToken = localStorage.getItem("prc_authToken");
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "GET",
  };
  return XHR(URL, options);
}

function* filterNotification({ params }) {
  try {
    const response = yield call(filterNotificationAPI, params);
    yield put({
      type: constants.GET_NOTIFICATION_SUCCESS,
      response: response.data.results,
    });
  } catch (e) {
    toast.error("Failed to get filtered notification");
    yield put({ type: constants.RESET_REQUEST });
  }
}

async function markAsReadNotificationAPI(params, data) {
  const prc_authToken = await localStorage.getItem("prc_authToken");
  const URL = `${config.baseApiUrl}/api/v1/user_notifications/${params}/`;
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "PATCH",
    data,
  };
  return XHR(URL, options);
}

function* markAsReadNotification({ params, data, callBack }) {
  try {
    yield call(markAsReadNotificationAPI, params, data);
    callBack(true);
    // yield put({ type: constants.GET_NOTIFICATION })
  } catch (e) {
    toast.error("Failed to mark as read notification");
    yield put({ type: constants.RESET_REQUEST });
  }
}

async function registerDeviceAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken");
  const URL = `${config.baseApiUrl}/modules/fcmnotifications/device/fcm/`;
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "POST",
    data,
  };
  return XHR(URL, options);
}

function* registerDevice({ payload }) {
  try {
    yield call(registerDeviceAPI, payload);
  } catch (e) {}
}

export default all([
  takeLatest(constants.GET_NOTIFICATION, getNotification),
  takeLatest(constants.FILTER_NOTIFICATION, filterNotification),
  takeLatest(constants.MARK_AS_READ_NOTIFICATION, markAsReadNotification),
  takeLatest(constants.REGISTER_DEVICE, registerDevice),
]);
