import React from "react";

import { formatDate } from "utils/functions";
import { date_format } from "variables/date";

import goBackArrowIcon from "../../../../assets/img/goBackArrowIcon.png";

import "./style.scss";
import ExportBtn from "Containers/ChatsList/components/ExportBtn";

const ChatDetailsHeader = ({ chat, onBackClick, onExport, isExporting }) => {
  return (
    <div className="chat_header">
      <div className="chat_header_title_wrapper" onClick={onBackClick}>
        <img className="back-icon" src={goBackArrowIcon} alt="go back" />
        <span className="chat-header__title">Chat {chat?.id}</span>
      </div>
      <div className="chat_header_buttons">
        <div className="chat-info-column">
          <div className="chat-info-column-label">User ID:</div>
          <div className="chat-info-column-value">{chat?.user}</div>
        </div>
        <div className="chat-info-column-split" />
        <div className="chat-info-column">
          <div className="chat-info-column-label">Date:</div>
          <div className="chat-info-column-value">{formatDate(chat?.created_at, date_format.short)}</div>
        </div>
        <div className="chat-info-column-split" />
        <div className="chat-info-column">
          <div className="chat-info-column-label">Time:</div>
          <div className="chat-info-column-value">{formatDate(chat?.created_at, date_format.time)}</div>
        </div>
        <ExportBtn onExport={onExport} isExporting={isExporting} />
      </div>
    </div>
  );
};

export default ChatDetailsHeader;
