import React, { useEffect, useState } from "react";
import Images from "utils/Images";
import Switch from "react-switch";
import Pagination from "components/Pagination/Pagination";
import { connect } from "react-redux";
import "./style.css";

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  InputGroupAddon,
  Spinner,
  InputGroupText,
  InputGroup,
  Modal,
  Table,
} from "reactstrap";

//Actions
import {
  searchUsers,
  filterUsers,
  deleteUser,
  usersListRequest,
  createUserRequest,
  updateUserRequest,
  createUserFaluire,
  searchUsersFailure,
  filterUsersFailure,
  suspendUser,
  resetAllFilterData,
  getPreferredInterest,
  usersListSuccess,
  updateUserSuccess,
} from "./redux/actions";
import { getCategory } from "Containers/ListOfClientCalls/redux/actions";

//utils
import validator from "utils/validation";
import useForm from "utils/useForm";
import CButton from "components/Button";
import { IOS_APP_STORE_LINK, ANDROID_APP_STORE_LINK } from "variables/";

const Types = ["Trial Period", "Subscribed"];
const bottomChecks = [
  { title: "Commodities", key: "is_commodities" },
  { title: "Equities", key: "is_equities" },
  { title: "FX", key: "is_fX" },
  { title: "Policy", key: "is_policy" },
  { title: "Politics", key: "is_politics" },
  { title: "Presentations", key: "is_presentations" },
  { title: "Property", key: "is_property" },
  { title: "Rates", key: "is_rates" },
];

const UsersList = (props) => {
  const {
    usersListRequest,
    usersList,
    requesting,
    createUserRequest,
    updateUserRequest,
    BackendError,
    createUserFaluire,
    createRequesting,
    searchedUser,
    searchRequesting,
    deleteRequesting,
    getPreferredInterest,
    preferredData,
    preferdDataRequest,
  } = props;

  const [isToggle, setIsToggle] = useState(false);
  const [modal, setModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [trialPeriodCheck, setTrialPeriodCheck] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectPageValue, setSelectPageValue] = useState(1);
  const [activeType, setActiveTypes] = useState("");

  useEffect(() => {
    props.getCategory();
    usersListRequest({ offset: 0 });
  }, []);

  useEffect(() => {
    if (activeType) {
      const payload = {
        isSubscribed: activeType === "Subscribed" ? "True" : "False",
        isTrial: activeType === "Trial Period" ? "True" : "False",
      };
      props.filterUsers(payload, offset);
    } else {
      usersListRequest({ offset });
    }
  }, [offset]);

  const toggle = () => {
    setModal(!modal);
    setState(stateSchema);
    createUserFaluire(false);
    setTrialPeriodCheck(false);
  };

  const toggle1 = (item) => {
    setSelectedUser(item);
    if (item.is_subscribed) {
      setTrialPeriodCheck("Subscribed");
    } else if (item.is_trial_period) {
      setTrialPeriodCheck("Trial Period");
    }
    setModal1(!modal1);
  };

  const toggle2 = () => setModal2(!modal2);

  const onFilterUser = (type) => {
    const updatedType = activeType === type ? "" : type;
    if (updatedType) {
      const payload = {
        isSubscribed: updatedType === "Subscribed" ? "True" : "False",
        isTrial: updatedType === "Trial Period" ? "True" : "False",
      };
      props.filterUsers(payload, 0);
    } else {
      props.resetAllFilterData();
      setOffset(0);
      usersListRequest({ offset: 0 });
    }
    setActiveTypes(updatedType);
  };

  const onPressSearch = (text) => {
    if (text.length === 0) {
      props.resetAllFilterData();
      setSearchText("");
      setOffset(0);
      usersListRequest({ offset: 0 });
    } else {
      setSearchText(text);
      props.searchUsers({ email: text });
    }
  };

  const onSelectType = (item) => {
    if (item === trialPeriodCheck) {
      setTrialPeriodCheck(false);
    } else {
      setTrialPeriodCheck(item);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      setChecked(selectedUser?.is_suspended);
    }
  }, [selectedUser]);

  const stateSchema = {
    email: {
      value: "",
      error: "",
    },
    downloadLink: {
      value: IOS_APP_STORE_LINK,
      error: "",
    },
    downloadLinkTwo: {
      value: ANDROID_APP_STORE_LINK,
      error: "",
    },
    tempPassword: {
      value: "",
      error: "",
    },
    specific_email: {
      value: "",
      error: "",
    },
  };

  const validationStateSchema = {
    email: {
      required: true,
      validator: validator.email,
    },
    downloadLink: {
      required: true,
    },
    downloadLinkTwo: {
      required: true,
    },
    tempPassword: {
      required: true,
    },
    specific_email: {
      required: false,
    },
  };

  const { state, handleOnChange, disable, setState } = useForm(stateSchema, validationStateSchema);

  const handleCreateUser = () => {
    const data = {
      email: state.email.value,
      password: state.tempPassword.value,
      is_trial_period: trialPeriodCheck === "Trial Period" ? true : false,
      is_subscribed: trialPeriodCheck === "Subscribed" ? true : false,
      download_link: state.downloadLink.value,
      download_link_2: state.downloadLinkTwo.value,
    };
    createUserRequest(data, toggle);
  };

  const deleteUser = () => {
    const data = {
      id: selectedUser.id,
      del: { is_account_delete: true },
    };
    props.deleteUser(data, toggle2);
  };

  const handleUpdateUser = (type) => {
    const payload = {
      is_trial_period: type === "Trial Period",
      is_subscribed: type === "Subscribed",
      id: selectedUser?.id,
    };
    updateUserRequest(payload, () => onUserUpdateCallback(payload), offset);
  };

  const handleUserUpdate = (type, status) => {
    if (type === "suspend") {
      const payload = { is_suspended: status, id: selectedUser.id };
      props.suspendUser(payload, () => onUserUpdateCallback(payload), offset);
    }
  };

  const onUserUpdateCallback = (payload) => {
    try {
      if (searchedUser && searchedUser?.length) {
        let users = [...searchedUser];
        const index = users.findIndex((user) => user.id === payload.id);
        users[index] = { ...searchedUser[index], ...payload };
        props.updateUserSuccess(users);
        offset && setOffset(0);
      }
      setModal1(!modal1);
    } catch (error) {}
  };

  return (
    <div className="ml-lg-5 mr-lg-5 mr-3 ml-3">
      <Row>
        <Col md="12">
          <Card className="card-plain" style={{ backgroundColor: "#f2f2f2" }}>
            <CardHeader style={{ paddingLeft: 10 }}>
              <Row
                className="d-md-flex m-0 p-0"
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Col lg="3" className="p-0 ">
                  <CardTitle
                    tag="h4"
                    style={{
                      // fontFamily: "Inter",
                      fontWeight: "400",
                      fontSize: "20px",
                      lineHeight: "25px",
                      color: "#4A5981",
                      marginTop: "24px",
                    }}
                  >
                    List of Users
                  </CardTitle>
                </Col>
                <Col lg="8">
                  <div
                    className="header d-md-flex"
                    style={{
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <InputGroup
                      className="no-border"
                      style={{
                        borderRadius: "8px",
                        marginTop: "10px",
                        marginRight: "20px",
                      }}
                    >
                      <Input
                        defaultValue=""
                        placeholder="Search..."
                        type="text"
                        style={{
                          backgroundColor: "white",
                          height: "46px",
                        }}
                        value={searchText}
                        onChange={(e) => onPressSearch(e.target.value)}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText style={{ backgroundColor: "white" }}>
                          {searchRequesting ? (
                            <Spinner size="sm" />
                          ) : (
                            <img
                              style={{
                                height: "17px",
                                width: "17px",
                              }}
                              alt=""
                              src={Images.CSearch}
                            />
                          )}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <Dropdown isOpen={isToggle} toggle={() => setIsToggle(!isToggle)}>
                      <DropdownToggle
                        aria-haspopup={true}
                        color="default"
                        data-toggle="dropdown"
                        id="navbarDropdownMenuLink"
                        nav
                        style={{
                          paddingLeft: 0,
                          width: 121,
                          padding: 0,
                        }}
                      >
                        <Button
                          onClick={() => {
                            setIsToggle(!isToggle);
                          }}
                          className=" text-capitalize"
                          style={{
                            backgroundColor: "#fff",
                            height: "46px",
                            width: "108px",
                            color: "#F01716",
                            border: "1px solid #EAEAEA",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "8px",
                          }}
                        >
                          <img
                            alt=""
                            style={{
                              height: "16px",
                              width: "19px",
                              borderRadius: 0,
                              marginRight: "7px",
                            }}
                            src={Images.filtericon}
                          />
                          Filter
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu persist aria-labelledby="navbarDropdownMenuLink" right>
                        {Types.map((type, i) => {
                          return (
                            <DropdownItem
                              key={i}
                              style={{
                                color: activeType === type ? "#F01716" : "#838181",
                                fontWeight: "600",
                                fontSize: "12px",
                              }}
                              onClick={() => onFilterUser(type)}
                            >
                              {type}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                    <CButton
                      onClick={toggle}
                      className="btnStyle"
                      title="+ Add New User"
                      btnStyle={{
                        backgroundColor: "#F01716",
                        height: 46,
                        borderRadius: "6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        color: "white",
                        border: "none",
                        fontWeight: 600,
                        fontSize: "14px",
                        padding: "14px 37px 14px",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </CardHeader>

            <CardBody style={{ marginTop: "-63px" }}>
              {usersList?.results?.length > 0 ? (
                <>
                  <Table
                    responsive
                    style={{
                      borderTop: "3px solid transparent",
                      overflow: "hidden",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            padding: "25px",
                            fontSize: "12px",
                            color: "#939393",
                          }}
                        >
                          EMAIL
                        </th>
                        <th
                          style={{
                            fontSize: "12px",
                            color: "#939393",
                          }}
                        >
                          USER ID
                        </th>

                        <th
                          style={{
                            paddingRight: "20px",
                            fontSize: "12px",
                            color: "#939393",
                          }}
                          className="text-right"
                        >
                          TRIAL PERIOD
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersList &&
                        usersList?.results?.map((item, i) => (
                          <>
                            <tr
                              style={{
                                backgroundColor: "white",
                                marginLeft: "25px",
                                marginRight: "30px",
                                border: "3px solid transparent",
                                borderRadius: "8px",
                              }}
                            >
                              <td
                                onClick={() => {
                                  toggle1(item);
                                  getPreferredInterest(item.id);
                                }}
                                style={{
                                  padding: "25px",
                                  borderTopLeftRadius: "10px",
                                  borderBottomLeftRadius: "10px",
                                  color: "#F01716",
                                  cursor: "pointer",
                                }}
                              >
                                {item.email}
                              </td>
                              <td>{item.id}</td>
                              <td
                                className="text-right"
                                style={{
                                  paddingRight: "52px",
                                  borderTopRightRadius: "10px",
                                  borderBottomRightRadius: "10px",
                                }}
                              >
                                {item.is_trial_period === true && item.is_subscribed === false ? "Yes" : "No"}
                              </td>
                            </tr>
                            <tr style={{ height: "12px" }}></tr>
                          </>
                        ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <Row
                  style={{
                    marginTop: 25,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <ActivityIndicator
                    requesting={requesting}
                    data={false}
                    // message={"No User Found."}
                  /> */}
                  {requesting ? (
                    <Spinner size="md" />
                  ) : (
                    <p style={{ textAlign: "center", marginTop: 10 }}>No user found.</p>
                  )}
                </Row>
              )}

              {usersList && usersList.count > 10 && !searchText ? (
                <Row style={{ alignItems: "center", justifyContent: "center" }}>
                  <Pagination
                    totalCount={usersList && usersList.count}
                    offset={offset}
                    setOffset={setOffset}
                    selectPageValue={selectPageValue}
                    setSelectPageValue={setSelectPageValue}
                  />
                </Row>
              ) : (
                <></>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: "444px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px",
          }}
        >
          <label className="p-0 m-0" style={{ fontSize: "16px", fontWeight: "700", color: "##000000" }}>
            Add New User
          </label>
          <img
            alt=""
            onClick={toggle}
            style={{
              height: 15,
              width: 15,
              marginLeft: "20px",
            }}
            src={Images.cross}
          />
        </div>

        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Row className="mt-md-4" style={{ justifyContent: "center" }}>
            <Col sm="12">
              <Row style={{ justifyContent: "center" }}>
                <Col md="12">
                  <FormGroup>
                    <Label
                      style={{
                        color: "rgba(0, 0, 0, 1)",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                      sm="6"
                    >
                      EMAIL
                    </Label>
                    <Input
                      placeholder="Enter Email Address"
                      type="text"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: 52,
                        color: "#000000",
                      }}
                      value={state.email.value}
                      onChange={(e) => {
                        handleOnChange("email", e.target.value);
                        createUserFaluire(false);
                      }}
                    />
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      {state.email.error && (
                        <label
                          style={{
                            color: "red",
                          }}
                        >
                          {state.email.error}
                        </label>
                      )}
                      {BackendError.email && (
                        <label
                          style={{
                            color: "red",
                            padding: 5,
                          }}
                        >
                          {BackendError.email.toString()}
                        </label>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ display: "flex", marginTop: 20 }}>
                {Types.map((type, i) => {
                  return (
                    <FormGroup key={i} check>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",

                          marginLeft: 20,
                        }}
                      >
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={() => onSelectType(type)}
                            checked={type === trialPeriodCheck}
                          />
                          <span
                            className="form-check-sign"
                            style={{
                              height: "10px",
                              backgroundColor: "#3A0F7D",
                            }}
                          />
                          <span
                            style={{
                              color: "#000000",
                              // fontFamily: "Inter",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "17px",
                            }}
                          >
                            {type}
                          </span>
                        </Label>
                      </div>
                    </FormGroup>
                  );
                })}
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <Col md="12">
                  <FormGroup style={{ marginTop: 10 }}>
                    <Label
                      style={{
                        color: "rgba(0, 0, 0, 1)",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                      sm="6"
                    >
                      DOWNLOAD LINK (iOS)
                    </Label>
                    <Input
                      placeholder="Paste iOS download link here"
                      type="text"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: 52,
                        color: "#000000",
                      }}
                      value={state.downloadLink.value}
                      onChange={(e) => {
                        handleOnChange("downloadLink", e.target.value);
                        createUserFaluire(false);
                      }}
                    />
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      {state.downloadLink.error && (
                        <label
                          style={{
                            color: "red",
                          }}
                        >
                          {state.downloadLink.error}
                        </label>
                      )}
                      {BackendError.download_link && (
                        <label
                          style={{
                            color: "red",
                            padding: 5,
                          }}
                        >
                          {BackendError.download_link.toString()}
                        </label>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <Col md="12">
                  <FormGroup style={{ marginTop: 10 }}>
                    <Label
                      style={{
                        color: "rgba(0, 0, 0, 1)",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                      sm="6"
                    >
                      DOWNLOAD LINK (ANDROID)
                    </Label>
                    <Input
                      placeholder="Paste android download link here"
                      type="text"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: 52,
                        color: "#000000",
                      }}
                      value={state.downloadLinkTwo.value}
                      onChange={(e) => {
                        handleOnChange("downloadLinkTwo", e.target.value);
                        createUserFaluire(false);
                      }}
                    />
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      {state.downloadLinkTwo.error && (
                        <label
                          style={{
                            color: "red",
                          }}
                        >
                          {state.downloadLinkTwo.error}
                        </label>
                      )}
                      {BackendError.download_link_2 && (
                        <label
                          style={{
                            color: "red",
                            padding: 5,
                          }}
                        >
                          {BackendError.download_link_2.toString()}
                        </label>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ justifyContent: "center" }}>
                <Col md="12">
                  <FormGroup style={{ marginTop: 15 }}>
                    <Label
                      style={{
                        color: "rgba(0, 0, 0, 1)",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                      sm="6"
                    >
                      TEMPORARY PASSWORD
                    </Label>
                    <Input
                      placeholder="Enter Password"
                      type="text"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: 52,
                        color: "#000000",
                      }}
                      value={state.tempPassword.value}
                      onChange={(e) => {
                        handleOnChange("tempPassword", e.target.value);
                        createUserFaluire(false);
                      }}
                    />
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      {state.tempPassword.error && (
                        <label
                          style={{
                            color: "red",
                          }}
                        >
                          {state.tempPassword.error}
                        </label>
                      )}
                      {BackendError.password && (
                        <label
                          style={{
                            color: "red",
                            padding: 5,
                          }}
                        >
                          {BackendError.password[0]}
                        </label>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row
                style={{
                  justifyContent: "center",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <Button
                  onClick={toggle}
                  className="mr-lg-3"
                  style={{
                    backgroundColor: "#fff",
                    color: "#F01716",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleCreateUser();
                    // setModal(false)
                  }}
                  style={{
                    backgroundColor: "#F01716",
                    color: "#FFFF",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50,
                  }}
                  disabled={disable || !trialPeriodCheck}
                >
                  {createRequesting ? <Spinner size="sm" /> : "Send"}
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal isOpen={modal1} toggle={toggle1} style={{ maxWidth: "444px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px",
          }}
        >
          <label className="p-0 m-0" style={{ fontSize: "16px", fontWeight: "700", color: "##000000" }}>
            Specific User Details
          </label>
          <img
            alt=""
            onClick={() => setModal1(false)}
            style={{
              height: 15,
              width: 15,
              marginLeft: "20px",
            }}
            src={Images.cross}
          />
        </div>

        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Row className="mt-md-4" style={{ justifyContent: "center" }}>
            <Col sm="12">
              <Row style={{ justifyContent: "center" }}>
                <Col md="12">
                  <FormGroup>
                    <Label
                      style={{
                        color: "rgba(0, 0, 0, 1)",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                      sm="6"
                    >
                      EMAIL
                    </Label>
                    <Input
                      placeholder="Enter Email Address"
                      type="text"
                      style={{
                        backgroundColor: "white",
                        height: 52,
                        color: "#000",
                      }}
                      disabled={true}
                      defaultValue={selectedUser.email}
                      value={selectedUser.email}
                    />
                    {state.specific_email.error && (
                      <label
                        style={{
                          color: "red",
                        }}
                      >
                        {state.specific_email.error}
                      </label>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ display: "flex", marginTop: 15 }}>
                {Types.map((type, i) => {
                  return (
                    <FormGroup key={i} check>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: 20,
                        }}
                      >
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={trialPeriodCheck === type}
                            defaultValue={
                              selectedUser.is_subscribed ? selectedUser.is_subscribed : selectedUser.is_trial_period
                            }
                            onChange={() => {
                              setTrialPeriodCheck(type);
                              handleUpdateUser(type);
                              setSearchText("");
                            }}
                          />
                          <span
                            className="form-check-sign"
                            style={{
                              height: "10px",
                              backgroundColor: "#3A0F7D",
                            }}
                          />
                          <span
                            style={{
                              color: "#000000",
                              // fontFamily: "Inter",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "17px",
                            }}
                          >
                            {type}
                          </span>
                        </Label>
                      </div>
                    </FormGroup>
                  );
                })}
              </Row>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: -14,
                }}
              >
                <p
                  style={{
                    // fontFamily: "Inter",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "#000000",
                    marginTop: 15,
                  }}
                >
                  SUSPEND USER
                </p>
                <FormGroup
                  className="my-2"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: -20,
                  }}
                >
                  {checked ? (
                    <p
                      className="mr-2"
                      style={{
                        marginTop: 6,
                        fontFamily: "Inter",
                        fontWeight: "400",
                        fontSize: "10px",
                        color: "#4A5981",
                      }}
                    >
                      ON
                    </p>
                  ) : (
                    <p
                      className="mr-2"
                      style={{
                        marginTop: 6,
                        fontFamily: "Inter",
                        fontWeight: "400",
                        fontSize: "10px",
                        color: "#4A5981",
                      }}
                    >
                      OFF
                    </p>
                  )}
                  <div style={{}}>
                    <Switch
                      onColor="#F01716"
                      height={26}
                      width={46}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={() => {
                        setChecked(!checked);
                        handleUserUpdate("suspend", !checked);
                        setSearchText("");
                      }}
                      checked={checked}
                    />
                  </div>
                </FormGroup>
              </div>

              <hr style={{ marginTop: -5 }} />
              <Row style={{ display: "flex", marginTop: 15 }}>
                {preferdDataRequest ? (
                  <Col md="12">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Spinner size="lg" />
                    </div>
                  </Col>
                ) : (
                  preferredData &&
                  bottomChecks.map((item, i) => {
                    return (
                      <Col md="6">
                        <FormGroup check>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              pointerEvents: "none",
                              marginLeft: 20,
                            }}
                          >
                            <Label check>
                              <Input type="checkbox" checked={preferredData[item.key]} disabled />
                              <span
                                className="form-check-sign disabled"
                                style={{
                                  height: "10px",
                                  backgroundColor: "#3A0F7D",
                                }}
                              />
                              <span
                                style={{
                                  color: "#000000",
                                  // fontFamily: "Inter",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "17px",
                                }}
                              >
                                {item.title}
                              </span>
                            </Label>
                          </div>
                        </FormGroup>
                      </Col>
                    );
                  })
                )}
              </Row>

              <Row
                style={{
                  justifyContent: "center",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <Button
                  onClick={() => {
                    setModal1(false);
                  }}
                  className="mr-lg-3"
                  style={{
                    backgroundColor: "#fff",
                    color: "#F01716",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    toggle2();
                    setModal1(false);
                  }}
                  style={{
                    backgroundColor: "#F01716",
                    color: "#FFFF",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50,
                  }}
                >
                  <img
                    alt=""
                    style={{
                      height: "18px",
                      width: "15px",
                      marginTop: -2,
                      marginRight: "10px",
                    }}
                    src={Images.del}
                  />
                  Delete
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        isOpen={modal2}
        toggle1={toggle2}
        // style={{ maxWidth: 353 }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            alt=""
            style={{
              height: 45,
              width: 38,
              marginTop: "40px",
            }}
            src={Images.deleteDark}
          />
        </div>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
            fontSize: "22px",
            // fontFamily: "Libre Caslon Text",
            color: "#4A5981",
          }}
        >
          Delete User?
        </p>
        <p
          style={{
            marginTop: "-25px",
            padding: "35px",
            textAlign: "center",
            fontSize: "15px",
            fontFamily: "Libre Caslon Text",
            color: "#000000",
          }}
        >
          Are you sure, you want to delete this user? All the data related to this user will get lost if you delete it.
        </p>

        <Row style={{ justifyContent: "center", marginBottom: "25px" }}>
          <Button
            className="mr-lg-3"
            onClick={toggle2}
            style={{
              backgroundColor: "#fff",
              color: "#F01716",
              border: "1px solid #F01716",
              borderRadius: "8px",
              // width: "151px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => deleteUser()}
            style={{
              backgroundColor: "#F01716",
              color: "#FFFF",
              border: "1px solid #F01716",
              borderRadius: "8px",
              width: "151px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50,
            }}
          >
            {deleteRequesting ? <Spinner size="sm" /> : "Yes, Delete"}
          </Button>
        </Row>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  usersList: state.UsersList.usersList,
  requesting: state.UsersList.requesting,
  BackendError: state.UsersList.error,
  createRequesting: state.UsersList.createRequesting,
  searchRequesting: state.UsersList.searchRequesting,
  searchedUser: state.UsersList.searchedUser,
  filteredUser: state.UsersList.filteredUser,
  categories: state.getAllClientCallsReducer.categories,
  deleteRequesting: state.UsersList.deleteRequesting,
  preferredData: state.UsersList.preferredData,
  preferdDataRequest: state.UsersList.preferdDataRequest,
});
const mapDispatchToProps = (dispatch) => ({
  updateUserSuccess: (users) => dispatch(updateUserSuccess(users)),
  usersListSuccess: (users) => dispatch(usersListSuccess(users)),
  resetAllFilterData: () => dispatch(resetAllFilterData()),
  usersListRequest: (data) => dispatch(usersListRequest(data)),
  getCategory: (data) => dispatch(getCategory(data)),
  createUserRequest: (data, toggle) => dispatch(createUserRequest(data, toggle)),
  searchUsers: (data, toggle) => dispatch(searchUsers(data, toggle)),
  filterUsers: (data, offset) => dispatch(filterUsers(data, offset)),
  searchUsersFailure: (data) => dispatch(searchUsersFailure(data)),
  filterUsersFailure: (data) => dispatch(filterUsersFailure(data)),
  updateUserRequest: (data, modalVisible, offset) => dispatch(updateUserRequest(data, modalVisible, offset)),
  createUserFaluire: (data) => dispatch(createUserFaluire(data)),
  deleteUser: (data, toggle) => dispatch(deleteUser(data, toggle)),
  suspendUser: (data, setModal1, offset) => dispatch(suspendUser(data, setModal1, offset)),
  getPreferredInterest: (id) => dispatch(getPreferredInterest(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
