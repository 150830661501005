import React, { useState } from "react";

import PageWrapper from "WebVisual/PageWrapper";
import ChatComponent from "WebVisual/Components/Chat";

const Chat = () => {
  const [, setSearch] = useState("");

  return (
    <PageWrapper onChange={setSearch} title="Home" pageBodyBg="white" isPageBodyPadding={false}>
      <ChatComponent />
    </PageWrapper>
  );
};

export default Chat;
