import React, { useMemo } from "react";
import ChatAssistantIcon from "../../../../../assets/img/chatAssistantIcon.png";

import { FollowUpQuestions } from "../index";
import { FOLLOW_UP_QUESTIONS } from "../../constants/FollowUpQuestions.constant";
import { ChatFooter } from "WebVisual/Components/Chat/components";
import { getUniqueRandomItems } from "utils/functions";

import "./style.scss";

export const ChatWelcomeState = ({ onSubmit }) => {
  const [selectedQestion, setSelectedQuestion] = React.useState(null);
  const [initInputText, setInitInputText] = React.useState("");

  const [firstQuestion, secondQuestion, thirdQuestion] = useMemo(
    () => getUniqueRandomItems(FOLLOW_UP_QUESTIONS, 3),
    []
  );

  const handleFollowUpQuestionClick = (question) => {
    setInitInputText("");
    setTimeout(() => {
      setSelectedQuestion(question);
      setInitInputText(question);
    }, 0);
  };

  return (
    <div className="ChatWelcomeStateWrapper">
      <div className="ChatWelcomeState">
        <div className="ChatWelcomeStateLogo">
          <img className="ChatAssistantIcon" src={ChatAssistantIcon} alt="Logo" />
        </div>
        <div className="ChatWelcomeStateTitle">Hi, I’m PRC Macro Chat. What are we working on today?</div>
        <div className="ChatWelcomeStateFollowUpQuestions">
          <FollowUpQuestions
            selectedQestion={selectedQestion}
            firstQuestion={firstQuestion}
            secondQuestion={secondQuestion}
            thirdQuestion={thirdQuestion}
            onFollowUpQuestionClick={handleFollowUpQuestionClick}
          />
        </div>
        <div className="ChatWelcomeStateFooter">
          <ChatFooter initValue={initInputText} onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  );
};
