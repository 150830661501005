import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ChatFooter, ChatHeader, ChatMessages } from "./components";
import { constants } from "./redux";
import {
  useCopyChatConversation,
  useGetChatInfo,
  useHistoryState,
  useChatAutoScroll,
  useSendChatMessage,
  useHandleChatGoBack,
} from "./hooks";

import "./style.scss";
import { getUniqueRandomItems } from "utils/functions";
import { FOLLOW_UP_QUESTIONS } from "../ChatWidget/constants/FollowUpQuestions.constant";

const Chat = () => {
  const { id } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const [isAnimation, setIsAnimation] = React.useState(false);
  const [initInputText, setInitInputText] = React.useState("");
  const followUpQuestions = React.useMemo(() => getUniqueRandomItems(FOLLOW_UP_QUESTIONS, 3), [id]);

  const { messages, chat, isStreaming } = useGetChatInfo(id);
  const { handleCopyConversation } = useCopyChatConversation();
  const { handleClearState, message } = useHistoryState();
  const { chatMessagesContainerRef } = useChatAutoScroll(messages, isStreaming);
  const { handleSendMessage } = useSendChatMessage(chat);
  const { handleBackClick } = useHandleChatGoBack(handleClearState);

  const handleStartNewChat = () => {
    dispatch({
      type: constants.CREATE_CHAT,
      cbSuccess: (chat) => {
        history.push({
          pathname: `/user/chat/${chat.id}`,
          state: { isFromChat: true },
        });

        setInitInputText("clear");
      },
    });
  };

  const handleAddFeedback = ({ message_id, note, reaction }) => {
    dispatch({
      type: constants.ADD_CHAT_MESSAGE_REACTION,
      data: { message_id, note, reaction },
    });
  };

  const handleFollowUpQuestionClick = (question) => {
    setInitInputText("");
    setTimeout(() => {
      setInitInputText(question);
    }, 0);
  };

  useEffect(() => {
    if (message && chat) {
      handleSendMessage(message, 1500);
      handleClearState();
      setIsAnimation(true);
    }
  }, [message, chat, handleClearState, handleSendMessage]);

  return (
    <div className="chat-wrapper">
      <ChatHeader
        onBackClick={handleBackClick}
        onCopyConversation={handleCopyConversation}
        onStartNewChat={handleStartNewChat}
        isDisabledAddNewChatButton={!messages.length || isStreaming}
        isDisabledCopyConversationButton={!messages.length || isStreaming}
      />
      <div className="chat-content">
        {chat ? (
          <ChatMessages
            messages={messages}
            onAddFeedback={handleAddFeedback}
            onFollowUpQuestionClick={handleFollowUpQuestionClick}
            isStreaming={isStreaming}
            chatMessagesContainerRef={chatMessagesContainerRef}
            isAnimation={isAnimation}
            followUpQuestions={followUpQuestions}
          />
        ) : null}
      </div>
      <div className="chat-footer">
        <ChatFooter initValue={initInputText} onSubmit={handleSendMessage} isDisabledSubmitButton={isStreaming} />
      </div>
    </div>
  );
};

export default Chat;
