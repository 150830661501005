import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { Spinner } from "reactstrap";

import { FIND_BR_TAGS, FIND_SPECIAL_CHARACTERS } from "utils/regExp";

import "./style.scss";

export const MessageText = ({ isStreaming, message }) => {
  const messageContent = useMemo(() => {
    const parsedMessage = message.message.replaceAll(FIND_BR_TAGS, "  \n").replace(FIND_SPECIAL_CHARACTERS, "’");

    return parsedMessage;
  }, [message.message]);

  if (isStreaming && !messageContent.length) {
    return <div className="assistant_message_text_streaming loading">I'm researching PRC Macro archives</div>;
  }

  return (
    <div className="assistant_message_text">
      <ReactMarkdown>{messageContent}</ReactMarkdown>
      {isStreaming ? (
        <Spinner color="black" size="sm">
          Loading...
        </Spinner>
      ) : null}
      {!isStreaming && message.error ? <div className="assistant_message_text_error">{message.error}</div> : null}
    </div>
  );
};

export default MessageText;
