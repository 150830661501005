import { all, call, put, takeLatest } from "redux-saga/effects";

import { toast } from "react-hot-toast";
// config
import config from "config/app";

// utils
import XHR from "utils/XHR";

// types
import { CHANGE_EMAIL_REQUEST } from "./types";

// actions
import { changeEmailSuccess, changeEmailFaluire } from "./actions";

async function changeEmailAPI(data) {
  const URL = `${config.baseApiUrl}//api/v1/update_email/${data.id}/`;

  const prc_authToken = await localStorage.getItem("prc_authToken");
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "PATCH",
    data,
  };
  return XHR(URL, options);
}

function* changeEmailFunction({ data }) {
  try {
    const response = yield call(changeEmailAPI, data);
    yield put(changeEmailSuccess(response.data));

    toast.success("Success !");
  } catch (e) {
    let err = e?.response?.data;

    yield put(changeEmailFaluire(err));
  }
}
export default all([takeLatest(CHANGE_EMAIL_REQUEST, changeEmailFunction)]);
