import UsersList from "Containers/UsersList";
import ChatsList from "Containers/ChatsList";
import ChatDetails from "Containers/ChatDetails";
import ListOfArticles from "Containers/ListOfArticles";
import ListOfPodcasts from "Containers/ListOfPodcasts";
import ListOfClientCalls from "Containers/ListOfClientCalls";
import Analytics from "Containers/Analytics";
import FeedBack from "Containers/FeedBack";
import Notification from "Containers/Notification";
import ForgotPassword from "Containers/ForgotPassword";
import ResetPassword from "Containers/ResetPassword";
import SignIn from "Containers/SignIn";

import Images from "utils/Images";
import Home from "WebVisual/Home";
import Chat from "WebVisual/Chat";
import Podcast from "WebVisual/Podcast";
import Favourite from "WebVisual/Favourite";
import Settings from "WebVisual/Settings";
import Notifications from "WebVisual/Notifications";
import ClientCalls from "WebVisual/ClientCalls";
import ArticleDetail from "WebVisual/Components/ArticleDetail";
import Setting from "Containers/AdminSetting";

const routes = [
  {
    path: "/login",
    // name: "SignIn",
    // icon: "nc-icon nc-calendar-60",
    component: SignIn,
    layout: "/auth",
    image: Images.img7,
  },
  {
    path: "/dashboard",
    name: "LIST OF USERS",
    icon: "nc-icon nc-bank",
    component: UsersList,
    layout: "/admin",
    image: Images.img1,
  },
  {
    path: "/list-of-articles",
    name: "LIST OF ARTICLES",
    icon: "nc-icon nc-bank",
    component: ListOfArticles,
    layout: "/admin",
    image: Images.img2,
  },
  {
    path: "/list-of-podcasts",
    name: "LIST OF PODCASTS",
    icon: "nc-icon nc-bank",
    component: ListOfPodcasts,
    layout: "/admin",
    image: Images.img3,
  },
  {
    path: "/list-of-client-calls",
    name: "LIST OF CLIENT CALLS",
    icon: "nc-icon nc-box",
    component: ListOfClientCalls,
    layout: "/admin",
    image: Images.img4,
  },
  {
    path: "/list-of-chats/:id",
    component: ChatDetails,
    layout: "/admin",
    isExclude: true,
  },
  {
    path: "/list-of-chats",
    name: "LIST OF CHATS",
    icon: "nc-icon nc-bank",
    component: ChatsList,
    layout: "/admin",
    image: Images.img9,
  },
  {
    path: "/feedBack",
    name: "FEEDBACK",
    icon: "nc-icon nc-chart-bar-32",
    component: FeedBack,
    layout: "/admin",
    image: Images.img5,
  },
  {
    path: "/analytics",
    name: "ANALYTICS",
    icon: "nc-icon nc-calendar-60",
    component: Analytics,
    layout: "/admin",
    image: Images.img6,
  },
  {
    path: "/notifications",
    name: "NOTIFICATIONS",
    icon: "nc-icon nc-calendar-60",
    component: Notification,
    layout: "/admin",
    image: Images.img7,
  },
  // {
  //   path: "/change-password",
  //   name: "change Password",
  //   icon: "nc-icon nc-calendar-60",
  //   component: ChangePassword,
  //   layout: "/admin",
  //   image: Images.image8
  // },
  // {
  //   path: "/change-Email",
  //   name: "Change Email",
  //   icon: "nc-icon nc-calendar-60",
  //   component: ChangeEmail,
  //   layout: "/admin",
  //   image: Images.emailImg
  // },
  {
    path: "/setting",
    name: "Account & Settings",
    icon: "nc-icon nc-setting-60",
    component: Setting,
    layout: "/admin",
    image: Images.adminSettingIcon,
  },

  {
    path: "/forgot-password",
    // name: "Forgot Password",
    // icon: "nc-icon nc-calendar-60",
    component: ForgotPassword,
    layout: "/auth",
    image: Images.img7,
  },
  {
    path: "/reset/",
    component: ResetPassword,
    layout: "/auth",
    image: Images.img7,
  },
  {
    path: "/home",
    component: Home,
    layout: "/user",
  },
  {
    path: "/chat/:id",
    component: Chat,
    layout: "/user",
  },
  {
    path: "/podcast",
    component: Podcast,
    layout: "/user",
  },
  {
    path: "/favourite",
    component: Favourite,
    layout: "/user",
  },
  {
    path: "/settings",
    component: Settings,
    layout: "/user",
  },
  {
    path: "/notifications",
    component: Notifications,
    layout: "/user",
  },
  {
    path: "/clientcalls",
    component: ClientCalls,
    layout: "/user",
  },
  {
    path: "/articledetail",
    component: ArticleDetail,
    layout: "/user",
  },
];

export default routes;
