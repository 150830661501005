export const FOLLOW_UP_QUESTIONS = [
  "What was the focus of the most recent CEWC and how was this different from the previous year?",
  "What can you tell me about He Lifeng?",
  "Who is the new head of CSRC and what is his background?",
  "Is Beijing going to introduce a durable goods swap program? Have they done this in the past and did it have an impact on sales?",
  "What have senior officials been looking at during their recent provincial inspection tours? Are there any policy signals?",
  "What are the projections for China’s green energy sector over the next five years?",
  "Is there any indication of changes in policies impacting the coal industry?",
  "What is the current outlook for the manufacturing sector’s growth and key challenges?",
  "How are property market dynamics shifting in response to recent housing policy changes?",
  "Does China have plans to build new nuclear power plants?",
  "How much has China invested in the semiconductor industry?",
  "What are the recent policy facilities created by PBOC to support equity markets?",
  "What is the latest data on the manufacturing inventory restocking cycle?",
  "What trends are emerging in household debt and savings rates?",
  "Is there any sign of a shift in manufacturing inventory restocking cycles?",
  "What are the expected growth trends in electric vehicle production?",
  "How much is China investing in AI technology, and what are its primary goals?",
  "What is the state of 5G infrastructure rollout, and who are the main players?",
  "How is the government supporting innovation in the clean energy sector?",
  "What are the economic implications of China’s relationship with Russia?",
  "What role does China play in the global supply chain for renewable energy resources?",
  "What trends are emerging from China's participation in the Regional Comprehensive Economic Partnership (RCEP)?",
  "What are your expectations for power grid investment in the next few years?",
  "How has your outlook for the currency evolved?",
  "What is the potential impact of tariffs on the Chinese economy in 2025?",
  "What are the risks for silver in 2025?",
  "Outline recent stimulus measures for me.",
  "Will China extend durable swap subsidies into 2025?",
];
