import React, { useEffect, useState } from "react";
import { CardBody, Table } from "reactstrap";

import Pagination from "components/Pagination/Pagination";
import { TABLE_COULMNS } from "Containers/ChatsList/constants";

import "./style.scss";

const ChatsListTable = ({ chatsList, filter, requesting, onChangeFilter, onChatRowClick }) => {
  const [selectPageValue, setSelectPageValue] = useState(1);

  useEffect(() => {
    if (!filter.offset) {
      setSelectPageValue(1);
    }
  }, [filter.offset]);

  return (
    <CardBody>
      {chatsList?.results?.length || requesting ? (
        <Table responsive className="table">
          <TableHeader />
          <TableBody chatsList={chatsList} onChatRowClick={onChatRowClick} />
        </Table>
      ) : (
        <div className="empty-state-row">
          <p className="empty-state">{filter.search ? "No matches found" : "No conversations have been started yet"}</p>
        </div>
      )}

      {chatsList && chatsList.count > filter.limit ? (
        <div className="pagination-wrapper">
          <Pagination
            totalCount={chatsList && chatsList.count}
            offset={filter.offset}
            setOffset={(offset) => onChangeFilter({ offset })}
            selectPageValue={selectPageValue}
            setSelectPageValue={setSelectPageValue}
          />
        </div>
      ) : null}
    </CardBody>
  );
};

const TableBody = ({ chatsList, onChatRowClick }) => {
  return (
    <tbody>
      {chatsList?.results?.map((chat) => (
        <React.Fragment key={chat.id}>
          <tr
            onClick={() => onChatRowClick(chat)}
            style={{
              backgroundColor: "white",
              border: "3px solid transparent",
            }}
          >
            {TABLE_COULMNS.map((column, index) => (
              <td
                key={column.title}
                className="column"
                style={{
                  padding: "25px",
                  cursor: "pointer",
                  borderTopLeftRadius: index === 0 ? "10px" : undefined,
                  borderBottomLeftRadius: index === 0 ? "10px" : undefined,
                  borderTopRightRadius: index === TABLE_COULMNS.length - 1 ? "10px" : undefined,
                  borderBottomRightRadius: index === TABLE_COULMNS.length - 1 ? "10px" : undefined,
                }}
              >
                {column?.get(chat)}
              </td>
            ))}
          </tr>
          <tr style={{ height: "12px" }}></tr>
        </React.Fragment>
      ))}
    </tbody>
  );
};

const TableHeader = () => {
  return (
    <thead>
      <tr>
        {TABLE_COULMNS.map((column) => (
          <th
            key={column.title}
            style={{
              padding: "25px",
              fontSize: "12px",
              color: "#939393",
            }}
          >
            {column.title}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default ChatsListTable;
