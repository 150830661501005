import { useSelector } from "react-redux";
import { copyToClipboard, showSuccessToast, getArticleUrlBySourceUrl } from "utils/functions";
import { USER_TYPE_MATRIX } from "../constants";
import { FIND_BR_TAGS, FIND_SPECIAL_CHARACTERS } from "utils/regExp";

export const useCopyChatConversation = () => {
  const { chat } = useSelector((state) => state.VisualChatReducer);

  const handleCopyConversation = () => {
    copyToClipboard(prepareConversation(chat));
    showSuccessToast("Conversation copied successfully");
  };

  return {
    handleCopyConversation,
  };
};

export const prepareMessageConversation = (message) => {
  const parsedMessage = message.message.replace(FIND_BR_TAGS, "\n").replace(FIND_SPECIAL_CHARACTERS, "'");
  const errorMessage = message.error;
  const messageText = errorMessage ? errorMessage : parsedMessage;

  let r = `${USER_TYPE_MATRIX[message.type]}: ${messageText}\n\n`;

  if (message.sources.length) {
    r += `Sources:\n`;
    message.sources.forEach((source) => {
      r += `${getArticleUrlBySourceUrl(source.url)}\n`;
    });

    r += "\n";
  }

  return r;
};

const prepareConversation = (chat) => {
  const messages = (chat?.messages || []).reduce((r, item) => {
    r += prepareMessageConversation(item);

    return r;
  }, "");

  return messages;
};
