import React from "react";

import goBackArrowIcon from "../../../../../assets/img/goBackArrowIcon.png";
import AddNewChatButton from "../AddNewChatButton";
import CopyConversationButton from "../CopyConversationButton";

import "./style.scss";

const ChatHeader = ({
  onBackClick,
  onCopyConversation,
  onStartNewChat,
  isDisabledAddNewChatButton,
  isDisabledCopyConversationButton,
}) => {
  return (
    <div className="chat_header">
      <div className="chat_header_title_wrapper" onClick={onBackClick}>
        <img className="back-icon" src={goBackArrowIcon} alt="go back" />
        <span className="chat-header__title">Go Back</span>
      </div>
      <div className="chat_header_buttons">
        <CopyConversationButton
          onCopyConversation={onCopyConversation}
          isDisabled={isDisabledCopyConversationButton}
          isMobile={true}
        />
        <AddNewChatButton
          onAddNewChat={onStartNewChat}
          onCopyConversation={onCopyConversation}
          isDisabled={isDisabledAddNewChatButton}
        />
      </div>
    </div>
  );
};

export default ChatHeader;
