import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Button } from "reactstrap";

import "./style.scss";

const PdfViewer = ({ pdfLink }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [scale, setScale] = useState(1.0); // Default scaling
  const [numPages, setNumPages] = useState(null); // Total number of pages

  // Handle window resizing
  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Callback for when the document is loaded
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Dynamic page width
  const pageWidth = Math.min(width * 0.9, 800); // 90% of screen width or max 800px

  // Scale adjustment handlers
  const increaseScale = () => setScale((prevScale) => Math.min(prevScale + 0.1, 3.0)); // Max scale
  const decreaseScale = () => setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min scale

  return (
    <div className="pdf-viewer">
      <div className="pdf-viewer-zoom">
        <Button className="zoom text-capitalize" onClick={decreaseScale}>
          Zoom Out
        </Button>
        <Button className="zoom text-capitalize" onClick={increaseScale}>
          Zoom In
        </Button>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "90vh",
            overflowY: "auto",
            overflowX: "auto",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Document file={pdfLink} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (_, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} width={pageWidth} />
            ))}
          </Document>
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
