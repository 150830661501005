import {
  GET_ALL_CHATS_FALUIRE,
  GET_ALL_CHATS_REQUEST,
  GET_ALL_CHATS_SUCCESS,
  CHANGE_CHATS_FILTER_REQUEST,
  CLEAR_CHATS_FILTER_REQUEST,
  EXPORT_ALL_CHATS_REQUEST,
  EXPORT_ALL_CHATS_SUCCESS,
} from "./types";

const filterChats = {
  offset: 0,
  limit: 10,
  search: "",
};

const initialState = {
  chatsList: false,
  requesting: false,
  filter: { ...filterChats },
  isExporting: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CHATS_REQUEST:
      return {
        ...state,
        requesting: true,
      };

    case GET_ALL_CHATS_SUCCESS:
      return {
        ...state,
        requesting: false,
        chatsList: action.data,
      };

    case GET_ALL_CHATS_FALUIRE:
      return {
        ...state,
        requesting: false,
      };

    case CLEAR_CHATS_FILTER_REQUEST:
      return {
        ...state,
        filter: {
          ...filterChats,
        },
      };

    case CHANGE_CHATS_FILTER_REQUEST:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.data,
        },
      };
    case EXPORT_ALL_CHATS_REQUEST:
      return {
        ...state,
        isExporting: true,
      };
    case EXPORT_ALL_CHATS_SUCCESS:
      return {
        ...state,
        isExporting: false,
      };

    default:
      return state;
  }
};
