import React from "react";
// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Container, Col, Row } from "reactstrap";

import { useHistory } from "react-router-dom";

import Images from "utils/Images";
import { useSelector } from "react-redux";

const NotFound = () => {
  const history = useHistory();

  const user = useSelector((state) => state?.SignIn?.user);

  const handleLogin = () => {
    if (user?.user?.is_superuser) {
      history.push("/admin/dashboard");
    } else {
      history.push("/user/home");
    }
  };

  return (
    <div>
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="6" md="8" sm="">
              <Form action="" className="form" method="">
                <Card className="card-login" style={{ marginTop: "8rem", boxShadow: "none" }}>
                  <CardHeader>
                    <CardHeader>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "0px",
                        }}
                      >
                        <img style={{ alignSelf: "center" }} src={Images.cardLogo} alt="Logo" />
                      </div>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <br />
                    <FormGroup>
                      <div style={{ marginTop: "30%" }}>
                        <h1
                          style={{
                            textAlign: "center",
                            fontWeight: "Bold",
                            fontFamily: "khula",
                            marginTop: "40%",
                          }}
                        >
                          Lost your way?
                        </h1>

                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            fontFamily: "khula",
                          }}
                        >
                          Sorry, we can't find that page. You'll find loads to explore on the home page.
                        </p>
                      </div>

                      <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Button
                          onClick={handleLogin}
                          className="px-md-5 text-capitalize rounded-pill"
                          style={{
                            backgroundColor: "#F01716",
                            color: "white",
                            height: "54px",
                            fontSize: "16px",
                            alignSelf: "center",
                          }}
                        >
                          Login
                        </Button>
                      </div>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default NotFound;
