import { all, call, put, takeLatest } from "redux-saga/effects";

import { toast } from "react-hot-toast";
// config
import config from "config/app";

// utils
import XHR from "utils/XHR";

// types
import { CHANGE_PASSWORD_REQUEST } from "./types";

// actions
import { changePasswordSuccess, changePasswordFaluire } from "./actions";

async function changePasswordAPI(data) {
  const URL = `${config.baseApiUrl}/api/v1/change_password/`;
  const prc_authToken = await localStorage.getItem("prc_authToken");
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`,
    },
    method: "POST",
    data,
  };
  return XHR(URL, options);
}

function* changePasswordFunction({ data }) {
  try {
    const response = yield call(changePasswordAPI, data);
    yield put(changePasswordSuccess(response.data));

    toast.success("Success !");
  } catch (e) {
    let err = e?.response?.data;

    yield put(changePasswordFaluire(err));
  }
}
export default all([takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordFunction)]);
