import { useCallback, useEffect, useRef } from "react";
import { constants } from "../redux";
import { useDispatch } from "react-redux";

const MESSAGE_EVENT = {
  MESSAGE: "message",
  CHUNK: "chunk",
  FULL_ANSWER: "full_answer",
  SOURCES: "sources",
  IMAGES: "images",
  ERROR: "error",
};

export const useSendChatMessage = (chat) => {
  const dispatch = useDispatch();
  const controllerRef = useRef(null);

  const stopRequest = useCallback(() => {
    if (controllerRef.current) {
      controllerRef.current.abort(); // Cancel the event stream
      controllerRef.current = null; // Optionally clear the ref to avoid retaining unnecessary references
    }
  }, []);

  const handleSendMessage = useCallback(
    (message, delay = 0) => {
      if (!controllerRef.current) {
        controllerRef.current = new AbortController();
      }

      if (chat) {
        setTimeout(() => {
          dispatch({
            type: constants.CHAT_ASK_QUESTION,
            data: { chat_id: chat.id, message },
            signal: controllerRef.current.signal,
            onMessage: (ev) => {
              switch (ev.event) {
                case MESSAGE_EVENT.MESSAGE: {
                  dispatch({
                    type: constants.CHAT_ADD_USER_MESSAGE,
                    response: JSON.parse(ev.data),
                  });
                  break;
                }
                case MESSAGE_EVENT.CHUNK:
                  dispatch({
                    type: constants.CHAT_CHUNK_ASSISTANT_MESSAGE,
                    response: ev.data,
                  });
                  break;
                case MESSAGE_EVENT.FULL_ANSWER:
                  dispatch({
                    type: constants.CHAT_UPDATE_LAST_ASSISTANT_MESSAGE,
                    response: { id: JSON.parse(ev.data).id },
                  });
                  break;
                case MESSAGE_EVENT.SOURCES:
                  dispatch({
                    type: constants.CHAT_UPDATE_LAST_ASSISTANT_MESSAGE,
                    response: { sources: JSON.parse(ev.data) },
                  });
                  break;
                case MESSAGE_EVENT.IMAGES:
                  dispatch({
                    type: constants.CHAT_UPDATE_LAST_ASSISTANT_MESSAGE,
                    response: { images: JSON.parse(ev.data) },
                  });
                  break;
                case MESSAGE_EVENT.ERROR:
                  dispatch({
                    type: constants.CHAT_UPDATE_LAST_ASSISTANT_MESSAGE,
                    response: { error: ev.data },
                  });
                  break;
                default:
                  break;
              }
            },
            onError: (e) => {
              dispatch({
                type: constants.CHAT_UPDATE_LAST_ASSISTANT_MESSAGE,
                response: {
                  error: "An unexpected error occurred. Please try again.",
                },
              });

              stopRequest();

              throw e;
            },
            onClose: () => {
              dispatch({
                type: constants.CHAT_STOP_ASSISTANT_MESSAGE_STREAMING,
              });
            },
          });
        }, delay);
      }
    },
    [dispatch, chat, stopRequest]
  );

  useEffect(() => {
    return () => {
      stopRequest();
    };
  }, [stopRequest]);

  return {
    handleSendMessage,
  };
};
