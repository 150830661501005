import storage from "redux-persist/lib/storage";
import ArticlesReducer from "Containers/ListOfArticles/redux/reducer";
import ForgotPassword from "Containers/ForgotPassword/redux/reducer";
import SignIn from "Containers/SignIn/redux/reducer";
import { getPodcastReducer } from "Containers/ListOfPodcasts/redux";
import { getAdminFeedbackReducer } from "Containers/FeedBack/redux";
import UsersList from "Containers/UsersList/redux/reducer";
import ChatsList from "Containers/ChatsList/redux/reducer";
import ChatDetails from "Containers/ChatDetails/redux/reducer";
import { NotificationReducer } from "Containers/Notification/redux";
import getAllClientCallsReducer from "Containers/ListOfClientCalls/redux/reducer";
import ChangePassword from "Containers/ChangePassword/redux/reducer";
import ChangeEmail from "Containers/ChangeEmail/redux/reducer";
import ResetPassword from "Containers/ResetPassword/redux/reducer";
import { VisualHomeReducer } from "WebVisual/Components/ArticleCard/redux";
import { VisualChatWidgetReducer } from "WebVisual/Components/ChatWidget/redux";
import { VisualChatReducer } from "WebVisual/Components/Chat/redux";
import { VisualClientCallsReducer } from "WebVisual/Components/ClientCalls/redux";
import { VisualFavouritesReducer } from "WebVisual/Components/FavouriteArticles/redux";
import { VisualPodcastReducer } from "WebVisual/Components/PodcastList/redux";
import { VisualNotificationReducer } from "WebVisual/Components/Notifications/redux";
import { VisualSettingsReducer } from "WebVisual/Components/Settings/redux";
import { persistReducer } from "redux-persist";

const signInPersistConfig = {
  key: "SignIn",
  storage,
  timeout: null,
};

export const combinedReducers = (history) => ({
  SignIn: persistReducer(signInPersistConfig, SignIn),
  ForgotPassword,
  getPodcastReducer,
  ArticlesReducer,
  getAdminFeedbackReducer,
  UsersList,
  ChatsList,
  ChatDetails,
  NotificationReducer,
  getAllClientCallsReducer,
  ChangePassword,
  ChangeEmail,
  ResetPassword,
  VisualHomeReducer,
  VisualChatWidgetReducer,
  VisualChatReducer,
  VisualClientCallsReducer,
  VisualFavouritesReducer,
  VisualPodcastReducer,
  VisualNotificationReducer,
  VisualSettingsReducer,
});
