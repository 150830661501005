import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-hot-toast";

import config from "config/app";
import XHR from "utils/XHR";

import { GET_ALL_CHATS_REQUEST, EXPORT_ALL_CHATS_REQUEST } from "./types";
import { chatsListFaluire, chatsListSuccess, exportAllChatsSuccess } from "./actions";
import { downloadFile } from "utils/functions";

async function getChatsAPI(data) {
  const accessToken = localStorage.getItem("prc_authToken");

  let URL = `${config.baseApiUrl}/api/v1/admin_chat/?limit=${data.limit}&offset=${data.offset}`;

  if (data.search) {
    URL += `&search=${data.search}`;
  }

  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json",
    },
    method: "GET",
  };

  return XHR(URL, options);
}

function* getChats({ data }) {
  try {
    const response = yield call(getChatsAPI, data);
    yield put(chatsListSuccess(response?.data));
  } catch (e) {
    toast.error("Failed to fetch chats");
    yield put(chatsListFaluire(e));
  }
}

async function exportChatsAPI(data) {
  const accessToken = localStorage.getItem("prc_authToken");

  let URL = `${config.baseApiUrl}/api/v1/admin_chat/export-csv/`;
  if (data.search) {
    URL += `?search=${data.search}`;
  }

  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`,
    },
    method: "GET",
  };

  return XHR(URL, options);
}

function* exportChats({ data }) {
  try {
    const { fileName, ...rest } = data;
    const response = yield call(exportChatsAPI, rest);

    downloadFile(response.data, fileName);

    toast.success("Exported. Your file has been successfully exported.");
  } catch {
    toast.error("Export failed. Please try again later.");
  } finally {
    yield put(exportAllChatsSuccess());
  }
}

export default all([takeLatest(GET_ALL_CHATS_REQUEST, getChats), takeLatest(EXPORT_ALL_CHATS_REQUEST, exportChats)]);
