import { all } from "redux-saga/effects";
// import { push } from "connected-react-router"

import ForgotPassword from "Containers/ForgotPassword/redux/saga";
import SignIn from "Containers/SignIn/redux/saga";
import ListOfPodcasts from "Containers/ListOfPodcasts/redux";
import UsersList from "Containers/UsersList/redux/saga";
import ChatsList from "Containers/ChatsList/redux/saga";
import ChatDetails from "Containers/ChatDetails/redux/saga";
import Articles from "Containers/ListOfArticles/redux/sagas";
import FeedBack from "Containers/FeedBack/redux";
import Notification from "Containers/Notification/redux";
import getAllClientCalls from "Containers/ListOfClientCalls/redux/saga";
import ChangePassword from "Containers/ChangePassword/redux/saga";
import ChangeEmail from "Containers/ChangeEmail/redux/saga";
import ResetPassword from "Containers/ResetPassword/redux/saga";
import VisualHomeSaga from "WebVisual/Components/ArticleCard/redux";
import VisuaChatWidgetSaga from "WebVisual/Components/ChatWidget/redux";
import VisuaChatSaga from "WebVisual/Components/Chat/redux";
import VisualClientCallsSaga from "WebVisual/Components/ClientCalls/redux";
import VisualFavouriteArticlesSaga from "WebVisual/Components/FavouriteArticles/redux";
import VisualPodcastListSaga from "WebVisual/Components/PodcastList/redux";
import VisualNotificationSaga from "WebVisual/Components/Notifications/redux";
import VisualSettingsSaga from "WebVisual/Components/Settings/redux";

export function* mainSaga() {
  yield all([
    ForgotPassword,
    SignIn,
    ListOfPodcasts,
    Articles,
    FeedBack,
    UsersList,
    Notification,
    getAllClientCalls,
    ChangePassword,
    ChangeEmail,
    ResetPassword,
    VisualHomeSaga,
    VisualClientCallsSaga,
    VisualFavouriteArticlesSaga,
    VisualPodcastListSaga,
    VisualNotificationSaga,
    VisualSettingsSaga,
    VisuaChatWidgetSaga,
    VisuaChatSaga,
    ChatsList,
    ChatDetails,
  ]);
}
