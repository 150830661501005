import React from "react";

import ExportBtn from "../ExportBtn";
import SearchInput from "components/SearchInput";

import "./style.scss";

const ChatsListHeader = ({ onChangeSearch, search, onExpoprt, searchRequesting, isExporting }) => {
  return (
    <div className="card-header-wrapper">
      <div className="left-card-header">Chats</div>
      <div className="right-card-header">
        <div className="search">
          <SearchInput onChangeSearch={onChangeSearch} value={search} searchRequesting={searchRequesting} />
        </div>
        <ExportBtn isExporting={isExporting} onExport={onExpoprt} />
      </div>
    </div>
  );
};

export default ChatsListHeader;
