import React from "react";

const CButton = ({ title = "", className = "", loading = false, btnStyle = {}, ...props }) => {
  return (
    <button style={{ ...btnStyle }} className={className} onClick={() => props.onClick && props.onClick()}>
      {title}
    </button>
  );
};

export default CButton;
