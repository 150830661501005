import React from "react";

import { REACTION_TYPE } from "WebVisual/Components/Chat/constants";

import chatLikeIcon from "../../../../../../../assets/img/chatWhiteLikeIcon.png";
import chatWhiteDislikeIcon from "../../../../../../../assets/img/chatWhiteDislikeIcon.png";

import "./style.scss";

export const FeedbackViewResult = ({ message }) => {
  const reaction = message?.reaction;

  if (!reaction) {
    return null;
  }

  return (
    <div className={`feedback-view-result ${reaction.reaction}`}>
      <div className="feedback-view-result-header">
        <img
          src={reaction.reaction === REACTION_TYPE.LIKE ? chatLikeIcon : chatWhiteDislikeIcon}
          alt="feedback"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
        <span className="feedback-view-result-header-text">
          {reaction.reaction === REACTION_TYPE.LIKE ? "User liked this response" : "User disliked this response"}
        </span>
      </div>
      <div className="feedback-view-result-content">{reaction.note}</div>
    </div>
  );
};

export default FeedbackViewResult;
