import React, { useState, useEffect } from "react";
import { Input, Spinner } from "reactstrap";

import Images from "utils/Images";
import { useDebounce } from "hooks";

import "./style.scss";

const SearchInput = ({ onChangeSearch, value, searchRequesting }) => {
  const [inputValue, setInputValue] = useState(value || "");

  const debounceValue = useDebounce(inputValue, 500);

  const handleChangeSearch = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  useEffect(() => {
    if (debounceValue !== value && debounceValue !== undefined) {
      onChangeSearch(debounceValue);
    }
  }, [debounceValue, onChangeSearch, inputValue, value]);

  return (
    <div className="search-input-wrapper">
      <Input
        value={inputValue}
        onChange={handleChangeSearch}
        placeholder="Search..."
        type="text"
        style={{
          backgroundColor: "white",
          height: "46px",
          width: "100%",
          padding: "0 30px 0 16px",
        }}
      />
      <div className="search-icon-block">
        {searchRequesting ? (
          <Spinner size="sm" />
        ) : (
          <img
            src={Images.CSearch}
            style={{
              height: "17px",
              width: "17px",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SearchInput;
