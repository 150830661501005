const REACT_APP_ENV = process.env.REACT_APP_ENV || "dev";

const dev = {
  env: REACT_APP_ENV,
  baseApiUrl: "http://localhost:8000",
};

const qa = {
  ...dev,
  baseApiUrl: "https://api.qa.prcmacro.com",
};

const uat = {
  ...dev,
  baseApiUrl: "https://api.uat.prcmacro.com",
};

const prod = {
  ...dev,
  baseApiUrl: "https://api.prcmacro.com",
};

const config = {
  dev,
  qa,
  uat,
  prod,
};

const configElement = config[REACT_APP_ENV];

export default configElement;
