import { GET_CHAT_REQUEST_SUCCESS } from "./types";

const initialState = {
  chat: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHAT_REQUEST_SUCCESS:
      return {
        ...state,
        chat: action.data,
      };
    default:
      return state;
  }
};
