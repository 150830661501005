import React from "react";
import { Button, Spinner } from "reactstrap";

import "./style.scss";

const ExportBtn = ({ isExporting, onExport }) => {
  return (
    <div className="export-btn-wrapper">
      <Button className="export-btn" color="danger" disabled={isExporting} onClick={() => onExport()}>
        Export as CSV
        {isExporting ? <Spinner size="sm" color="light" /> : null}
      </Button>
    </div>
  );
};

export default ExportBtn;
