import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { GET_ALL_CHATS_REQUEST, CHANGE_CHATS_FILTER_REQUEST, CLEAR_CHATS_FILTER_REQUEST } from "./redux/types";
import { ChatsListHeader, ChatsListTable } from "./components/";
import { useExport } from "./hooks";

const UsersList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { chatsList, requesting, filter } = useSelector((state) => state.ChatsList);
  const { handleExport, isExporting } = useExport(filter);

  const handleChangeFilter = (data) => {
    dispatch({
      type: CHANGE_CHATS_FILTER_REQUEST,
      data,
    });
  };

  const handleSearch = (search) => {
    handleChangeFilter({ search, offset: 0 });
  };

  const handleChatRowClick = (chat) => {
    history.push(`/admin/list-of-chats/${chat.id}`);
  };

  useEffect(() => {
    dispatch({
      type: GET_ALL_CHATS_REQUEST,
      data: filter,
    });
  }, [dispatch, filter]);

  useEffect(() => {
    return () => {
      dispatch({
        type: CLEAR_CHATS_FILTER_REQUEST,
      });
    };
  }, [dispatch]);

  return (
    <div className="ml-lg-5 mr-lg-5 mr-3 ml-3">
      <ChatsListHeader
        search={filter.search}
        isExporting={isExporting}
        onChangeSearch={handleSearch}
        onExpoprt={() => handleExport()}
      />
      <ChatsListTable
        requesting={requesting}
        chatsList={chatsList}
        filter={filter}
        onChangeFilter={handleChangeFilter}
        onChatRowClick={handleChatRowClick}
      />
    </div>
  );
};

export default UsersList;
