import {
  GET_ALL_CHATS_REQUEST,
  GET_ALL_CHATS_SUCCESS,
  GET_ALL_CHATS_FALUIRE,
  CHANGE_CHATS_FILTER_REQUEST,
  EXPORT_ALL_CHATS_SUCCESS,
  EXPORT_ALL_CHATS_REQUEST,
} from "./types";

export const chatsListRequest = (data) => ({
  type: GET_ALL_CHATS_REQUEST,
  data,
});

export const chatsListSuccess = (data) => ({
  type: GET_ALL_CHATS_SUCCESS,
  data,
});

export const chatsListFaluire = (data) => ({
  type: GET_ALL_CHATS_FALUIRE,
  data,
});

export const changeChatListFilterRequest = (data) => ({
  type: CHANGE_CHATS_FILTER_REQUEST,
  data,
});

export const exportAllChatsRequest = (data) => ({
  type: EXPORT_ALL_CHATS_REQUEST,
  data,
});

export const exportAllChatsSuccess = () => ({
  type: EXPORT_ALL_CHATS_SUCCESS,
});
