import React, { useState, useEffect, useRef } from "react";
import Images from "utils/Images";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "./style.css";

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  CardText,
  Spinner,
} from "reactstrap";
import moment from "moment";
import ActivityIndicator from "components/ActivityIndicator";
import Pagination from "components/Pagination/Pagination";
import {
  filterClientCall,
  deleteClientCall,
  getAllClientCalls,
  createClientCall,
  updateClientCall,
  createClientCallFailure,
  updateClientCallFailure,
  searchClientCall,
  getCategory,
  deleteClientCallFailure,
  filterClientCallFailure,
  searchClientCallFailure,
} from "./redux/actions";
import useForm from "utils/useForm";
import { characterEllipsis } from "utils/functions";
import CButton from "components/Button";

const ListOfClientCalls = (props) => {
  const {
    // addModal,
    // editModal,
    requesting,
    BackendError,
    updateRequesting,
    deleteRequesting,
    callRequesting,
    clientCalls,
    filterRequesting,
    categories,
    searchedResults,
    filteredResults,
    searchRequesting,
  } = props;

  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const [specificClient, setSpecificClient] = useState(false);
  const [offset, setOffset] = useState(0);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEdit1, setIsEdit1] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [pDFFile, setPDFFile] = useState(false);
  const [mediafile, setMediafile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [fromDate, setFromDate] = useState(false);
  const [toDate, setToDate] = useState(false);
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState("");
  const [categoryName, setCategoryName] = useState(false);
  const [mediaName, setMediaName] = useState("");
  const [pdfName, setPdfName] = useState("");
  const [selectPageValue, setSelectPageValue] = useState(1);

  const types = /(\.|\/)(mp3|mp4)$/i;
  const toggleUpdateModal = (item) => {
    if (updateRequesting) {
      props.updateClientCallFailure(false);
    }
    if (BackendError) {
      props.updateClientCallFailure(false);
    }
    if (item) {
      setSpecificClient(item);
    }
    if (isEdit) {
      setIsEdit(false);
      setIsEdit1(false);
    }
    setModal(!modal);
    setState(stateSchema);
    setIsEdited(false);
  };
  const togglePublishModal = () => {
    if (callRequesting) {
      createClientCallFailure(false);
    }
    if (BackendError) {
      props.createClientCallFailure(false);
    }
    setModal1(!modal1);
    setState(stateSchema);
  };
  const toggleDeleteModal = (item) => {
    if (deleteRequesting) {
      deleteClientCallFailure(false);
    }
    if (BackendError) {
      props.deleteClientCallFailure(false);
    }
    if (item) {
      setSpecificClient(item);
    }
    setModal2(!modal2);
  };

  useEffect(() => {
    props.getCategory();
    props.getAllClientCalls({ offset: 0 });
  }, []);

  useEffect(() => {
    props.getAllClientCalls({ offset });
  }, [offset]);

  const handleClick = () => setIsOpen(!isOpen);

  const clearFilter = () => {
    setCategoryName(false);
    setSearchText("");
    setFromDate(false);
    setToDate(false);
    setIsToggle(false);
    props.filterClientCallFailure();
  };

  const categoriesName = (value) => {
    if (value !== categoryName) {
      setCategoryName(value);
    } else {
      setCategoryName(false);
    }
  };

  const searchClientCall = (name) => {
    if (name?.length) {
      props.searchClientCall({ name });
    } else {
      props.searchClientCallFailure();
      setOffset(0);
      props.getAllClientCalls({ offset: 0 });
    }
    setSearchText(name);
  };

  const onFilterClient = () => {
    const data = {
      category: categoryName ? categoryName : "",
      dateFrom: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      dateTo: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
    };
    props.filterClientCall(data, setIsToggle);
  };

  const stateSchema = {
    title: {
      value: "",
      error: "",
    },
    short_summary: {
      value: "",
      error: "",
    },
    category: {
      value: "",
      error: "",
    },
    date: {
      value: "",
      error: "",
    },
    media: {
      value: "",
      error: "",
    },
    document: {
      value: "",
      error: "",
    },
  };
  const validationStateSchema = {
    title: {
      required: true,
      // validator: validator.name
    },
    short_summary: {
      required: true,
      // validator: validator.password,
    },
    category: {
      required: true,
      // validator: validator.email
    },
    date: {
      required: true,
      // validator: validator.email
    },
    media: {
      required: false,
      // validator: validator.email
    },
    document: {
      required: false,
    },
  };

  const { state, handleOnChange, disable, setState } = useForm(stateSchema, validationStateSchema);

  const getState = (state) => {
    let formData = new FormData();
    const keys = Object.keys(state);
    const values = Object.values(state);
    if (pDFFile) {
      formData.append("document", pDFFile);
    }
    keys.forEach((item, i) => {
      if (values[i].value) {
        formData.append(item, values[i].value);
      }
    });
    return formData;
  };

  const onEditPress = (item) => {
    const pdfFile = item?.document.split("/");
    const pdfFileName = pdfFile[pdfFile.length - 1];
    const mediaFile = item.media.split("/");
    const mediaFileName = mediaFile[mediaFile.length - 1];

    setIsEdit(true);
    setIsEdit1(true);
    setModal(!modal);
    handleOnChange("title", item?.title);
    handleOnChange("short_summary", item?.short_summary);
    handleOnChange("category", item?.category);
    handleOnChange("date", item?.date);
    setMediaName(mediaFileName);
    setPdfName(pdfFileName);
  };
  const handleUploadVideos = () => {
    inputRef1.current.click();
    return false;
  };
  const handleUploadPdf = () => {
    inputRef.current.click();
    return false;
  };
  const onPublish = async () => {
    const ApiData = {
      id: specificClient.id,
      formdata: getState(state),
    };
    await props.createClientCall(ApiData, togglePublishModal);
    // setPDFFile(false)
  };
  const onUpdate = async () => {
    const ApiData = {
      id: specificClient.id,
      formdata: getState(state),
    };
    await props.updateClientCall(ApiData, toggleUpdateModal);
    searchClientCall("");
  };

  const onDelete = async () => {
    const ApiData = {
      id: specificClient.id,
    };
    await props.deleteClientCall(ApiData, toggleDeleteModal);
  };

  return (
    <div className="ml-lg-5 mr-lg-5 mr-3 ml-3">
      <Row>
        <Col md="12">
          <Card className="card-plain ">
            <CardHeader style={{ paddingLeft: 10 }}>
              <Row
                className="d-md-flex m-0 p-0"
                style={{
                  justifyContent: "space-between",
                }}
              >
                <CardTitle
                  tag="h4"
                  style={{
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "25px",
                    color: "#4A5981",
                    marginTop: "24px",
                  }}
                >
                  List of Client Calls
                </CardTitle>
                <Col lg="8">
                  <div
                    className="header d-md-flex"
                    style={{
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <InputGroup
                      className="no-border"
                      style={{
                        borderRadius: "8px",
                        marginTop: "10px",
                        marginRight: "20px",
                      }}
                    >
                      <Input
                        defaultValue=""
                        placeholder="Search..."
                        type="text"
                        style={{
                          backgroundColor: "white",
                          height: "46px",
                          color: "#000",
                        }}
                        value={searchText}
                        onChange={(e) => searchClientCall(e.target.value)}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          style={{ backgroundColor: "white" }}
                          // onClick={onSearchPress}
                        >
                          {searchRequesting ? (
                            <Spinner size="sm" />
                          ) : (
                            <img
                              style={{
                                height: "17px",
                                width: "17px",
                              }}
                              alt=""
                              src={Images.CSearch}
                            />
                          )}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>

                    <Dropdown isOpen={isToggle} toggle={() => setIsToggle(!isToggle)}>
                      <DropdownToggle
                        aria-haspopup={true}
                        color="default"
                        data-toggle="dropdown"
                        id="navbarDropdownMenuLink"
                        nav
                        style={{
                          paddingLeft: 0,
                          width: 121,
                        }}
                      >
                        <Button
                          onClick={() => {
                            setIsToggle(!isToggle);
                          }}
                          className=" text-capitalize"
                          style={{
                            backgroundColor: "#fff",
                            height: "46px",
                            color: "#F01716",
                            border: "1px solid #EAEAEA",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: 108,
                            borderRadius: "8px",
                          }}
                        >
                          <img
                            alt=""
                            style={{
                              height: "16px",
                              width: "19px",
                              borderRadius: 0,
                              marginRight: "4px",
                            }}
                            src={Images.filterD}
                          />
                          Filter
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu
                        persist
                        aria-labelledby="navbarDropdownMenuLink"
                        right
                        style={{
                          // backgroundColor: "pink",
                          width: 300,
                          right: "10%",
                          // height: 500
                        }}
                      >
                        <div
                          style={{
                            color: "black",
                            // fontFamily: "Inter",
                            fontSize: 15,
                            fontWeight: "bold",
                            padding: 15,
                          }}
                        >
                          Filter by date
                        </div>

                        <div
                          style={{
                            color: "black",
                            // fontFamily: "Khula",
                            fontSize: 12,
                            fontWeight: "bold",
                            padding: 15,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexGrow: 1,
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignContent: "center",
                            }}
                          >
                            <div style={{ width: "48%" }} onClick={() => handleClick()}>
                              <p style={{ fontSize: 15 }}>From</p>
                              <div
                                style={{
                                  backgroundColor: "#F2F2F2",
                                  borderRadius: 6,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    paddingLeft: 15,
                                    paddingRight: 16,
                                  }}
                                >
                                  <img
                                    src={Images.calander}
                                    style={{
                                      width: 16,
                                      height: 15,
                                      marginRight: 6,
                                      borderRadius: 0,
                                    }}
                                    alt=""
                                  />

                                  <DatePicker
                                    placeholderText={"From date"}
                                    className="form-Data"
                                    selected={fromDate && fromDate}
                                    onChange={(date) => setFromDate(date)}
                                    dropdownMode="select"
                                    dateFormat="d MMM, yyyy"
                                    // todayButton="Ma"
                                    closeOnScroll={true}
                                    locale="hu"
                                    // calendarStartDay={1}
                                  />
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "48%" }}>
                              <p style={{ fontSize: 15 }}>To</p>
                              <div
                                style={{
                                  backgroundColor: "#F2F2F2",
                                  borderRadius: 6,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    paddingLeft: 15,
                                    paddingRight: 16,
                                  }}
                                >
                                  <img
                                    src={Images.calander}
                                    style={{
                                      width: 16,
                                      height: 15,
                                      marginRight: 6,
                                      borderRadius: 0,
                                    }}
                                    alt=""
                                  />
                                  <DatePicker
                                    className="form-Data"
                                    placeholderText={"To date"}
                                    selected={toDate && toDate}
                                    onChange={(date) => setToDate(date)}
                                    minDate={fromDate}
                                    dropdownMode="select"
                                    dateFormat="d MMM, yyyy"
                                    // todayButton="Ma"
                                    closeOnScroll={true}
                                    locale="hu"
                                    // calendarStartDay={1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <DropdownItem divider />
                        <div
                          style={{
                            color: "black",
                            // fontFamily: "Inter",
                            fontSize: 15,
                            fontWeight: "bold",
                            padding: 15,
                          }}
                        >
                          Filter by Category
                        </div>
                        {categories &&
                          categories.category.map((item, i) => {
                            return (
                              <div
                                style={{
                                  fontSize: 15,
                                  marginLeft: 16,
                                }}
                              >
                                <FormGroup check>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      // marginLeft: 40
                                    }}
                                  >
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        checked={item === categoryName}
                                        onChange={() => {
                                          categoriesName(item);
                                          // setCategoryName(item)
                                          // setCheck(!check)
                                        }}
                                      />
                                      <span
                                        className="form-check-sign"
                                        style={{
                                          height: "10px",
                                          backgroundColor: "#3A0F7D",
                                        }}
                                      />
                                      <span
                                        style={{
                                          color: "#000000",
                                          // fontFamily: "Inter",
                                          fontWeight: "400",
                                          fontSize: 15,
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item}
                                      </span>
                                    </Label>
                                  </div>
                                </FormGroup>
                              </div>
                            );
                          })}
                        <DropdownItem divider />
                        <div
                          style={{
                            color: "#838181",
                            // fontFamily: "Khula",
                            fontSize: 15,
                            padding: 10,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              style={{
                                backgroundColor: "#FFF",
                                height: 37,
                                borderRadius: "6px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                whiteSpace: "nowrap",
                                color: "#F01716",
                                border: "1px solid",
                              }}
                              className=" text-capitalize"
                              onClick={onFilterClient}
                            >
                              {filterRequesting ? (
                                <div
                                  style={{
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    width: "100%",
                                  }}
                                >
                                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                </div>
                              ) : (
                                "Apply Filter"
                              )}
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "#F1F1F1",
                                height: 37,
                                borderRadius: "6px",
                                marginLeft: 10,
                                display: "flex",
                                alignItems: "center",
                                whiteSpace: "nowrap",
                                color: "#4A5981",
                              }}
                              onClick={clearFilter}
                              className=" text-capitalize"
                            >
                              <img
                                style={{
                                  height: 16,
                                  width: 15,
                                  marginRight: 5,
                                }}
                                alt=""
                                src={Images.clearLoader}
                              />
                              Clear
                            </Button>
                          </div>
                        </div>
                      </DropdownMenu>
                    </Dropdown>

                    {/* <Button
                      className="mr-md-n2 text-capitalize"
                      onClick={() => togglePublishModal()}
                      style={{
                        backgroundColor: "#F01716",
                        height: 46,
                        borderRadius: "6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        whiteSpace: "nowrap"
                      }}
                    >
                      Publish Client Call
                    </Button> */}

                    <CButton
                      onClick={togglePublishModal}
                      className="btnStyle"
                      btnStyle={{
                        backgroundColor: "#F01716",
                        height: 46,
                        borderRadius: "6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        color: "white",
                        border: "none",
                        fontWeight: 600,
                        fontSize: "14px",
                        padding: "14px 37px 14px",
                      }}
                      title="Publish Client Call"
                    />
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody style={{ marginBottom: "20px" }}>
              <Row className=" d-flex  px-3 ">
                {searchedResults && searchText ? (
                  searchedResults?.length > 0 ? (
                    searchedResults?.map((item) => (
                      <Col sm="6" className="mb-5">
                        <Card
                          body
                          className="p-0"
                          // onClick={() => toggleUpdateModal(item)}
                        >
                          <CardHeader
                            style={{
                              backgroundColor: "#000000",
                              height: 53,
                              borderRadius: "8px 8px 0px 0px",
                            }}
                          >
                            <img
                              alt=""
                              style={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "0px",
                                marginTop: -9,
                              }}
                              src={Images.cardLogo}
                            />
                          </CardHeader>
                          <CardTitle>
                            <p
                              style={{
                                // fontFamily: "Intel",
                                fontWeight: "600",
                                fontSize: "16px",
                                margin: "16px 13px 16px 13px",
                              }}
                            >
                              {item.title}
                            </p>
                          </CardTitle>
                          <CardText>
                            <p
                              style={{
                                marginLeft: "13px",
                                color: "#000000",
                                fontSize: "14px",
                                fontWeight: "400",
                                // fontFamily: "Inter",
                                lineHeight: "21px",
                                marginTop: -14,
                              }}
                            >
                              {item.short_summary}
                            </p>

                            <Row style={{ display: "flex", marginLeft: "13px" }}>
                              <p
                                style={{
                                  color: "#939393",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  // fontFamily: "Inter",
                                  lineHeight: "15px",
                                }}
                              >
                                Category:
                              </p>
                              <p
                                style={{
                                  color: "#000000",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  // fontFamily: "Inter",
                                  lineHeight: "15px",
                                  marginLeft: "4px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item.category}
                              </p>
                              <p
                                style={{
                                  color: "#939393",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  // fontFamily: "Inter",
                                  lineHeight: "15px",
                                  marginLeft: "31px",
                                }}
                              >
                                {item.date}
                              </p>
                            </Row>
                          </CardText>
                          <Row
                            style={{
                              border: "1px solid #EBEBEB",
                              width: "95%",
                              margin: "-10px 13px 1px 13px ",
                            }}
                          ></Row>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "13px",
                              marginBottom: "10px",
                            }}
                          >
                            <Button
                              onClick={() => {
                                setSpecificClient(item);
                                setTimeout(() => {
                                  onEditPress(item);
                                }, 200);
                              }}
                              className=" text-capitalize"
                              style={{
                                backgroundColor: "#fff",
                                height: "36px",
                                color: "#F01716",
                                fontSize: "12px",
                                fontWeight: "700px",
                                border: "1px solid #F01716",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "6px",
                                width: "78px",
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  marginTop: "-2px",
                                  marginRight: "4px",
                                }}
                                src={Images.edit}
                              />
                              Edit
                            </Button>
                            <Button
                              className="btn-link text-capitalize"
                              onClick={() => {
                                toggleDeleteModal(item);
                              }}
                              style={{
                                color: "#F01716",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: 12,
                                  width: 10,
                                  marginRight: "5px",
                                  marginTop: "-2px",
                                  borderRadius: "0px",
                                }}
                                src={Images.delete}
                              />
                              Delete
                            </Button>
                          </div>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <Col
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <p style={{ textAlign: "center" }}>No record found.</p>
                    </Col>
                  )
                ) : filteredResults ? (
                  filteredResults.length > 0 ? (
                    filteredResults.map((item) => (
                      <Col sm="6" className="mb-5">
                        <Card
                          body
                          className="p-0"
                          // onClick={() => toggleUpdateModal(item)}
                        >
                          <CardHeader
                            style={{
                              backgroundColor: "#000000",
                              height: 53,
                              borderRadius: "8px 8px 0px 0px",
                            }}
                          >
                            <img
                              alt=""
                              style={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "0px",
                                marginTop: -9,
                              }}
                              src={Images.cardLogo}
                            />
                          </CardHeader>
                          <CardTitle>
                            <p
                              style={{
                                // fontFamily: "Intel",
                                fontWeight: "600",
                                fontSize: "16px",
                                margin: "16px 13px 16px 13px",
                              }}
                            >
                              {item.title}
                            </p>
                          </CardTitle>
                          <CardText>
                            <p
                              style={{
                                marginLeft: "13px",
                                color: "#000000",
                                fontSize: "14px",
                                fontWeight: "400",
                                // fontFamily: "Inter",
                                lineHeight: "21px",
                                marginTop: -14,
                              }}
                            >
                              {item.short_summary}
                            </p>

                            <Row style={{ display: "flex", marginLeft: "13px" }}>
                              <p
                                style={{
                                  color: "#939393",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  // fontFamily: "Inter",
                                  lineHeight: "15px",
                                }}
                              >
                                Category:
                              </p>
                              <p
                                style={{
                                  color: "#000000",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  // fontFamily: "Inter",
                                  lineHeight: "15px",
                                  marginLeft: "4px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item.category}
                              </p>
                              <p
                                style={{
                                  color: "#939393",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  // fontFamily: "Inter",
                                  lineHeight: "15px",
                                  marginLeft: "31px",
                                }}
                              >
                                {item.date}
                              </p>
                            </Row>
                          </CardText>
                          <Row
                            style={{
                              border: "1px solid #EBEBEB",
                              width: "95%",
                              margin: "-10px 13px 1px 13px ",
                            }}
                          ></Row>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "13px",
                              marginBottom: "10px",
                            }}
                          >
                            <Button
                              onClick={() => {
                                setSpecificClient(item);
                                setTimeout(() => {
                                  onEditPress(item);
                                }, 200);
                              }}
                              className=" text-capitalize"
                              style={{
                                backgroundColor: "#fff",
                                height: "36px",
                                color: "#F01716",
                                fontSize: "12px",
                                fontWeight: "700px",
                                border: "1px solid #F01716",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "6px",
                                width: "78px",
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  marginTop: "-2px",
                                  marginRight: "4px",
                                }}
                                src={Images.edit}
                              />
                              Edit
                            </Button>
                            <Button
                              className="btn-link text-capitalize"
                              onClick={() => {
                                toggleDeleteModal(item);
                              }}
                              style={{
                                color: "#F01716",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: 12,
                                  width: 10,
                                  marginRight: "5px",
                                  marginTop: "-2px",
                                  borderRadius: "0px",
                                }}
                                src={Images.delete}
                              />
                              Delete
                            </Button>
                          </div>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <Col
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <p style={{ textAlign: "center" }}>No record found.</p>
                    </Col>
                  )
                ) : clientCalls && clientCalls?.results.length > 0 ? (
                  clientCalls.results.map((item) => {
                    // const doc = item.media.split("/")
                    // const docName = doc[doc.length - 1]
                    return (
                      <Col sm="6" className="mb-5">
                        <Card
                          body
                          className="p-0"
                          // onClick={() => toggleUpdateModal(item)}
                        >
                          <CardHeader
                            style={{
                              backgroundColor: "#000000",
                              height: 53,
                              borderRadius: "8px 8px 0px 0px",
                            }}
                          >
                            <img
                              alt=""
                              style={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "0px",
                                marginTop: -9,
                              }}
                              src={Images.cardLogo}
                            />
                          </CardHeader>
                          <CardTitle>
                            <p
                              style={{
                                // fontFamily: "Intel",
                                fontWeight: "600",
                                fontSize: "16px",
                                margin: "16px 13px 16px 13px",
                              }}
                            >
                              {item.title}
                            </p>
                          </CardTitle>
                          <CardText>
                            <p
                              style={{
                                marginLeft: "13px",
                                color: "#000000",
                                fontSize: "14px",
                                fontWeight: "400",
                                // fontFamily: "Inter",
                                lineHeight: "21px",
                                marginTop: -14,
                              }}
                            >
                              {item.short_summary}
                            </p>

                            <Row style={{ display: "flex", marginLeft: "13px" }}>
                              <p
                                style={{
                                  color: "#939393",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  // fontFamily: "Inter",
                                  lineHeight: "15px",
                                }}
                              >
                                Category:
                              </p>
                              <p
                                style={{
                                  color: "#000000",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  // fontFamily: "Inter",
                                  lineHeight: "15px",
                                  marginLeft: "4px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item.category}
                              </p>
                              <p
                                style={{
                                  color: "#939393",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  // fontFamily: "Inter",
                                  lineHeight: "15px",
                                  marginLeft: "31px",
                                }}
                              >
                                {item.date}
                              </p>
                            </Row>
                          </CardText>
                          <Row
                            style={{
                              border: "1px solid #EBEBEB",
                              width: "95%",
                              margin: "-10px 13px 1px 13px ",
                            }}
                          ></Row>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "13px",
                              marginBottom: "10px",
                            }}
                          >
                            <Button
                              onClick={() => {
                                setSpecificClient(item);
                                setTimeout(() => onEditPress(item), 200);
                              }}
                              className=" text-capitalize"
                              style={{
                                backgroundColor: "#fff",
                                height: "36px",
                                color: "#F01716",
                                fontSize: "12px",
                                fontWeight: "700px",
                                border: "1px solid #F01716",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "6px",
                                width: "78px",
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  marginTop: "-2px",
                                  marginRight: "4px",
                                }}
                                src={Images.edit}
                              />
                              Edit
                            </Button>
                            <Button
                              className="btn-link text-capitalize"
                              onClick={() => {
                                toggleDeleteModal(item);
                              }}
                              style={{
                                color: "#F01716",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: 12,
                                  width: 10,
                                  marginRight: "5px",
                                  marginTop: "-2px",
                                  borderRadius: "0px",
                                }}
                                src={Images.delete}
                              />
                              Delete
                            </Button>
                          </div>
                        </Card>
                      </Col>
                    );
                  })
                ) : (
                  <ActivityIndicator requesting={requesting} data={false} message={"No client call found."} />
                )}
              </Row>
            </CardBody>

            {clientCalls && clientCalls.count > 10 && !(searchedResults || filteredResults) ? (
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <Pagination
                  totalCount={clientCalls && clientCalls.count}
                  offset={offset}
                  setOffset={setOffset}
                  selectPageValue={selectPageValue}
                  setSelectPageValue={setSelectPageValue}
                />
              </Row>
            ) : null}
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={modal1}
        toggle={() => {
          if (callRequesting) {
            props.createClientCallFailure();
          }
          togglePublishModal();
        }}
        style={{ maxWidth: "762px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px",
          }}
        >
          <label className="p-0 m-0" style={{ fontSize: "16px", fontWeight: "700", color: "##000000" }}>
            Publish Client Call
          </label>
          <img
            alt=""
            onClick={() => togglePublishModal()}
            style={{
              height: 15,
              width: 15,
              marginLeft: "20px",
            }}
            src={Images.cross}
          />
        </div>

        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Row className="mt-md-4" style={{ justifyContent: "center" }}>
            <Col sm="12">
              <Row style={{ justifyContent: "center" }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                    sm="6"
                  >
                    TITLE
                  </Label>
                  <FormGroup>
                    <Input
                      placeholder=""
                      type="text"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "52px",
                      }}
                      onChange={(e) => handleOnChange("title", e.target.value)}
                    />
                  </FormGroup>
                  {state.title.error && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        textAlign: "left",
                        marginTop: -6,
                      }}
                    >
                      {state.title.error}
                    </label>
                  )}
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <div style={{ display: "none" }}>
                  <input
                    type="file"
                    accept=".mp3,.mp4"
                    value=""
                    ref={inputRef1}
                    onChange={(e) => {
                      e.preventDefault();
                      setMediafile(e.target.files[0]);
                      handleOnChange("media", e.target.files[0]);
                    }}
                  />
                </div>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                    sm="6"
                  >
                    UPLOAD FILE
                  </Label>
                  <div
                    style={{
                      background: "rgba(240, 23, 22, 0.2)",
                      height: "60px",
                      width: "100%",
                      borderRadius: "6px",
                      border: "1px dotted red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleUploadVideos}
                  >
                    {state.media.value?.name ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#F01716",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {state.media.value.name}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setMediafile(false);
                            setMediaName("");
                            handleOnChange("media", "");
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: "black",
                              width: 1,
                              height: 30,
                              marginTop: 15,
                              marginLeft: 12,
                            }}
                          ></p>
                          <img
                            alt=""
                            style={{
                              height: 15,
                              width: 15,
                              marginLeft: "20px",
                            }}
                            src={Images.cross}
                          />
                        </div>
                      </p>
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#F01716",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        + Upload Media File
                      </p>
                    )}
                  </div>
                  <p
                    style={{
                      color: "rgba(147, 147, 147, 1)",
                      // fontFamily: "Inter",
                      fontSize: "12px",
                      marginTop: 5,
                    }}
                  >
                    *Please upload file type MP4
                  </p>
                </Col>
              </Row>

              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <div style={{ display: "none" }}>
                  <input
                    type="file"
                    accept=".pdf"
                    ref={inputRef}
                    value=""
                    onChange={(e) => {
                      e.preventDefault();
                      setPDFFile(e.target.files[0]);
                      handleOnChange("document", e.target.files[0]);
                      setIsEdited(false);
                    }}
                  />
                </div>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                    sm="6"
                  >
                    UPLOAD PDF FILE
                  </Label>
                  <div
                    style={{
                      background: "#FFFFFF",
                      height: "60px",
                      width: "100%",
                      borderRadius: "6px",
                      border: "1px dotted red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleUploadPdf}
                  >
                    {isEdit && mediaName ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#000",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {characterEllipsis(mediaName, 6)}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setIsEdit(!isEdit);
                            setIsEdited(true);
                            // onEditPress()
                            handleOnChange("document", "");
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: "black",
                              width: 1,
                              height: 30,
                              marginTop: 15,
                              marginLeft: 12,
                            }}
                          ></p>
                          <img
                            alt=""
                            style={{
                              height: 15,
                              width: 15,
                              marginLeft: "20px",
                            }}
                            src={Images.cross}
                          />
                        </div>
                      </p>
                    ) : state.document?.value?.name ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#F01716",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {characterEllipsis(state.document.value.name, 6)}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setPDFFile(false);
                            setPdfName("");
                            handleOnChange("document", "");
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: "black",
                              width: 1,
                              height: 30,
                              marginTop: 15,
                              marginLeft: 12,
                            }}
                          ></p>
                          <img
                            alt=""
                            style={{
                              height: 15,
                              width: 15,
                              marginLeft: "20px",
                            }}
                            src={Images.cross}
                          />
                        </div>
                      </p>
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#F01716",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        + Upload PDF File
                      </p>
                    )}
                  </div>
                  <p
                    style={{
                      color: "rgba(147, 147, 147, 1)",
                      // fontFamily: "Inter",
                      fontSize: "12px",
                      marginTop: 5,
                    }}
                  >
                    *Please upload file type PDF
                  </p>
                </Col>
              </Row>

              <Row style={{ justifyContent: "center", marginTop: 10 }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                    sm="6"
                  >
                    SHORT SUMMARY
                  </Label>
                  <FormGroup>
                    <Input
                      placeholder=""
                      type="textarea"
                      style={{
                        backgroundColor: "#F2F2F2",
                      }}
                      onChange={(e) => handleOnChange("short_summary", e.target.value)}
                      maxlength="200"
                    />
                  </FormGroup>
                  {state.short_summary.error && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        textAlign: "left",
                        marginTop: -6,
                      }}
                    >
                      {state.short_summary.error}
                    </label>
                  )}
                </Col>
              </Row>
              <Row style={{ display: "flex", marginTop: 10 }}>
                <Col>
                  <FormGroup>
                    <Label
                      style={{
                        color: "#000000",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                      sm="6"
                    >
                      CATEGORY
                    </Label>
                    <div className="customselect1">
                      <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        style={{ backgroundColor: "#F2F2F2", height: "52px", border: "transparent" }}
                        onChange={(e) =>
                          handleOnChange("category", e.target.value === "Select Category" ? "" : e.target.value)
                        }
                      >
                        <option>Select Category</option>
                        {categories &&
                          categories?.category?.map((item, i) => {
                            return <option>{item}</option>;
                          })}
                      </Input>
                    </div>
                  </FormGroup>
                  {state.category.error && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        textAlign: "left",
                        marginTop: -6,
                      }}
                    >
                      {state.category.error}
                    </label>
                  )}
                  {BackendError.category && (
                    <label
                      style={{
                        color: "red",
                        padding: 5,
                      }}
                    >
                      {BackendError.category[0]}
                    </label>
                  )}
                </Col>
                <Col>
                  <FormGroup>
                    <Label
                      style={{
                        color: "#000000",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                      sm="6"
                    >
                      DATE
                    </Label>
                    <Input
                      placeholder=""
                      type="date"
                      style={{
                        backgroundColor: "#F2F2F2",

                        height: "52px",
                      }}
                      onChange={(e) => handleOnChange("date", e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row style={{ justifyContent: "center", marginBottom: "15px" }}>
                <Button
                  className="mr-lg-3"
                  onClick={() => {
                    if (callRequesting) {
                      props.createClientCallFailure();
                    }
                    togglePublishModal();
                  }}
                  style={{
                    backgroundColor: "#fff",
                    color: "#F01716",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "#F01716",
                    color: "#FFFF",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50,
                  }}
                  onClick={onPublish}
                  disabled={
                    callRequesting ||
                    disable ||
                    !pDFFile ||
                    !mediafile ||
                    !types.test(mediafile.name) ||
                    !pDFFile?.name?.includes(".pdf")
                  }
                >
                  {callRequesting ? <Spinner size="sm" /> : "Publish"}
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal isOpen={modal} toggle={() => toggleUpdateModal()} style={{ maxWidth: "762px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px",
          }}
        >
          <label className="p-0 m-0" style={{ fontSize: "16px", fontWeight: "600", color: "##000000" }}>
            Specific Client Call Details
          </label>
          <img
            alt=""
            onClick={() => toggleUpdateModal()}
            style={{
              height: 15,
              width: 15,
              marginLeft: "20px",
            }}
            src={Images.cross}
          />
        </div>

        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Row className="mt-md-4" style={{ justifyContent: "center" }}>
            <Col sm="12">
              <Row style={{ justifyContent: "center" }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                    sm="6"
                  >
                    TITLE
                  </Label>
                  <FormGroup>
                    <Input
                      // value={isEdit ? null : specificClient?.title}
                      placeholder=""
                      type="text"
                      style={{
                        backgroundColor: "#FFFFFF",
                        height: "52px",
                        color: "#000",
                      }}
                      // disabled={!isEdit}
                      defaultValue={state?.title?.value}
                      onChange={(e) => handleOnChange("title", e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <div style={{ display: "none" }}>
                  <input
                    type="file"
                    accept=".mp3,.mp4"
                    ref={inputRef1}
                    value=""
                    onChange={(e) => {
                      e.preventDefault();
                      setIsEdited(false);
                      setMediafile(e.target.files[0]);
                      setMediaName(e.target.files[0].name);
                      handleOnChange("media", e.target.files[0]);
                    }}
                  />
                </div>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                    sm="6"
                  >
                    MEDIA FILE
                  </Label>
                  <div
                    style={{
                      background: "#FFFFFF",
                      height: "60px",
                      width: "100%",
                      borderRadius: "6px",
                      border: "1px dotted red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => inputRef1.current.click()}
                  >
                    {isEdit && mediaName ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#000",
                          margin: 0,
                          padding: 0,
                          cursor: "pointer",
                        }}
                      >
                        {characterEllipsis(mediaName, 12)}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setIsEdit(!isEdit);
                            setIsEdited(true);
                            handleOnChange("media", "");
                            setMediaName("");
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: "black",
                              width: 1,
                              height: 30,
                              marginTop: 15,
                              marginLeft: 12,
                            }}
                          ></p>
                          <img
                            alt=""
                            style={{
                              height: 15,
                              width: 15,
                              marginLeft: "20px",
                            }}
                            src={Images.cross}
                          />
                        </div>
                      </p>
                    ) : state.media.value?.name ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#F01716",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {characterEllipsis(state.media.value.name, 12)}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setMediafile(false);
                            setMediaName("");
                            setIsEdited(true);
                            handleOnChange("media", "");
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: "black",
                              width: 1,
                              height: 30,
                              marginTop: 15,
                              marginLeft: 12,
                            }}
                          ></p>
                          <img
                            alt=""
                            style={{
                              height: 15,
                              width: 15,
                              marginLeft: "20px",
                            }}
                            src={Images.cross}
                          />
                        </div>
                      </p>
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#F01716",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        + Upload Media File
                      </p>
                    )}
                  </div>
                </Col>
              </Row>

              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <div style={{ display: "none" }}>
                  <input
                    type="file"
                    accept=".pdf"
                    ref={inputRef}
                    value=""
                    onChange={(e) => {
                      e.preventDefault();
                      setPDFFile(e.target.files[0]);
                      setPdfName(e.target.files[0].name);
                      handleOnChange("document", e.target.files[0]);
                      setIsEdited(false);
                    }}
                  />
                </div>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                    sm="6"
                  >
                    UPLOAD FILE
                  </Label>
                  <div
                    style={{
                      background: "#FFFFFF",
                      height: "60px",
                      width: "100%",
                      borderRadius: "6px",
                      border: "1px dotted red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => inputRef.current.click()}
                  >
                    {isEdit1 && pdfName ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#000",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {characterEllipsis(pdfName, 6)}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setIsEdit1(!isEdit1);
                            setIsEdited(true);
                            // onEditPress()
                            setPdfName("");
                            handleOnChange("document", "");
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: "black",
                              width: 1,
                              height: 30,
                              marginTop: 15,
                              marginLeft: 12,
                            }}
                          ></p>
                          <img
                            alt=""
                            style={{
                              height: 15,
                              width: 15,
                              marginLeft: "20px",
                            }}
                            src={Images.cross}
                          />
                        </div>
                      </p>
                    ) : state.document?.value?.name ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#F01716",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {characterEllipsis(state.document.value.name, 6)}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setPDFFile(false);
                            setPdfName("");
                            handleOnChange("document", "");
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: "black",
                              width: 1,
                              height: 30,
                              marginTop: 15,
                              marginLeft: 12,
                            }}
                          ></p>
                          <img
                            alt=""
                            style={{
                              height: 15,
                              width: 15,
                              marginLeft: "20px",
                            }}
                            src={Images.cross}
                          />
                        </div>
                      </p>
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#F01716",
                          margin: 0,
                          padding: 0,
                          cursor: "pointer",
                        }}
                      >
                        + Upload Pdf File
                      </p>
                    )}
                  </div>
                </Col>
              </Row>

              <Row style={{ justifyContent: "center", marginTop: 10 }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                    sm="6"
                  >
                    SHORT SUMMARY
                  </Label>
                  <FormGroup>
                    <Input
                      // value={isEdit ? null : specificClient?.short_summary}
                      placeholder=""
                      type="textarea"
                      style={{
                        backgroundColor: "white",
                        color: "#000",
                      }}
                      defaultValue={state.short_summary.value}
                      // disabled={!isEdit}
                      onChange={(e) => handleOnChange("short_summary", e.target.value)}
                      maxlength="200"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ display: "flex", marginTop: 10 }}>
                <Col>
                  <FormGroup>
                    <Label
                      style={{
                        color: "#000000",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                      sm="6"
                    >
                      CATEGORY
                    </Label>
                    <div className="customselect2">
                      <Input
                        id="exampleSelect"
                        name="select"
                        type="select"
                        style={{
                          backgroundColor: "white",
                          height: "52px",
                          color: "#000",
                          border: "transparent",
                        }}
                        // value={isEdit ? null : specificClient.category}
                        defaultValue={state.category.value}
                        // disabled={!isEdit}
                        onChange={(e) => handleOnChange("category", e.target.value)}
                      >
                        {categories &&
                          categories?.category?.map((item, i) => {
                            return <option>{item}</option>;
                          })}
                      </Input>
                    </div>
                  </FormGroup>
                  {BackendError.category && (
                    <label
                      style={{
                        color: "red",
                        padding: 5,
                      }}
                    >
                      {BackendError.category[0]}
                    </label>
                  )}
                </Col>
                <Col>
                  <FormGroup>
                    <Label
                      style={{
                        color: "#000000",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                      sm="6"
                    >
                      DATE
                    </Label>
                    <Input
                      placeholder=""
                      type="date"
                      style={{
                        backgroundColor: "white",
                        height: "52px",
                        color: "#000",
                      }}
                      // value={isEdit ? null : specificClient.date}
                      // disabled={!isEdit}
                      defaultValue={state.date.value}
                      onChange={(e) => handleOnChange("date", e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row style={{ justifyContent: "center", marginBottom: "25px" }}>
                <Button
                  onClick={toggleUpdateModal}
                  className="mr-lg-3"
                  style={{
                    backgroundColor: "#fff",
                    color: "#F01716",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50,
                  }}
                >
                  <img
                    alt=""
                    style={{
                      height: 12,
                      width: 10,
                      marginRight: "5px",
                      marginTop: "-4px",
                      borderRadius: "0px",
                    }}
                    src={Images.delete}
                  />
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "#F01716",
                    color: "#FFFF",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50,
                  }}
                  disabled={updateRequesting || disable || !pdfName.includes(".pdf") || !types.test(mediaName)}
                  onClick={onUpdate}
                >
                  {updateRequesting ? <Spinner size="sm" /> : "Save"}
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal isOpen={modal2} toggle={toggleDeleteModal}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              height: 45,
              width: 38,
              marginTop: "40px",
            }}
            alt=""
            src={Images.deleteDark}
          />
        </div>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
            fontSize: "22px",
            // fontFamily: "Libre Caslon Text",
            color: "#4A5981",
          }}
        >
          Delete Client call?
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "center",

            marginTop: "-25px",
            padding: "25px",
            textAlign: "center",
            fontSize: "15px",
            // fontFamily: "Libre Caslon Text",
            color: "#000000",
          }}
        >
          Are you sure, you want to delete this client call? All the data related to this client call will get lost if
          you delete it.
        </p>

        <Row style={{ justifyContent: "center", marginBottom: "25px" }}>
          <Button
            className="mr-lg-3"
            onClick={toggleDeleteModal}
            style={{
              backgroundColor: "#fff",
              color: "#F01716",
              border: "1px solid #F01716",
              borderRadius: "8px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50,
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#F01716",
              color: "#FFFF",
              border: "1px solid #F01716",
              borderRadius: "8px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50,
              width: "151px",
            }}
            onClick={() => onDelete()}
          >
            {deleteRequesting ? <Spinner size="sm" /> : "Yes, Delete"}
          </Button>
        </Row>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // user: state.SignIn.user,
  // addModal: state.getAllClientCallsReducer.addModal,
  // editModal: state.getAllClientCallsReducer.editModal,
  BackendError: state.getAllClientCallsReducer.error,
  requesting: state.getAllClientCallsReducer.requesting,
  clientCalls: state.getAllClientCallsReducer.clientCalls,
  filteredResults: state.getAllClientCallsReducer.filteredResults,
  callRequesting: state.getAllClientCallsReducer.callRequesting,
  updateRequesting: state.getAllClientCallsReducer.updateRequesting,
  deleteRequesting: state.getAllClientCallsReducer.deleteRequesting,
  filterRequesting: state.getAllClientCallsReducer.filterRequesting,
  searchRequesting: state.getAllClientCallsReducer.searchRequesting,
  searchedResults: state.getAllClientCallsReducer.searchedResults,
  categories: state.getAllClientCallsReducer.categories,
  categoryRequesting: state.getAllClientCallsReducer.categoryRequesting,
});

const mapDispatchToProps = (dispatch) => ({
  // toggleAddModal: data => dispatch(toggleAddModal(data)),
  // toggleEditModal: data => dispatch(toggleEditModal(data)),
  createClientCall: (data, toggle) => dispatch(createClientCall(data, toggle)),
  updateClientCall: (data, toggle) => dispatch(updateClientCall(data, toggle)),
  filterClientCall: (data, toggle) => dispatch(filterClientCall(data, toggle)),
  deleteClientCall: (data, toggle) => dispatch(deleteClientCall(data, toggle)),
  getAllClientCalls: (data) => dispatch(getAllClientCalls(data)),
  createClientCallFailure: (data) => dispatch(createClientCallFailure(data)),
  updateClientCallFailure: (data) => dispatch(updateClientCallFailure(data)),
  filterClientCallFailure: (data) => dispatch(filterClientCallFailure(data)),
  searchClientCallFailure: (data) => dispatch(searchClientCallFailure(data)),
  deleteClientCallFailure: (data) => dispatch(deleteClientCallFailure(data)),
  searchClientCall: (data) => dispatch(searchClientCall(data)),
  getCategory: (data) => dispatch(getCategory(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ListOfClientCalls);
