import { all, call, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";

import { toast } from "react-hot-toast";
// config
import config from "config/app";

// utils
import XHR from "utils/XHR";

// types
import { LOGIN_REQUEST } from "./types";

// actions
import { loginSuccess, loginFaluire } from "./actions";

function loginAPI(data) {
  const URL = `${config.baseApiUrl}/api/v1/login/`;
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    data,
  };

  return XHR(URL, options);
}

function* login({ data }) {
  try {
    const response = yield call(loginAPI, data);
    localStorage.setItem("prc_authToken", response?.data?.token);
    localStorage.setItem("userId", response?.data?.user?.id);
    localStorage.setItem("userEmail", response?.data?.user?.email);
    yield put(loginSuccess(response?.data));

    if (response?.data?.user?.is_superuser) {
      yield put(push({ pathname: "/admin/dashboard" }));
    } else {
      yield put(push({ pathname: "/user/home" }));
    }
  } catch (e) {
    if (e.message === "Network Error") {
      toast.error("Connection Error");
      yield put(loginFaluire());
    } else {
      let err = e?.response?.data?.non_field_errors
        ? e.response.data.non_field_errors
        : e?.response?.data["detail"]
        ? e.response?.data["detail"].toString()
        : e?.response?.data["category"]
        ? e.response?.data["category"].toString()
        : e.response?.data["logo"]
        ? "No logo was submitted."
        : "Something went wrong.";
      yield put(loginFaluire());
      toast.error(err);
    }
  } finally {
    yield put(loginFaluire());
  }
}

export default all([takeLatest(LOGIN_REQUEST, login)]);
