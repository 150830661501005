import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Card, CardHeader, CardText, CardTitle, Col, Row } from "reactstrap";

import Images from "utils/Images";
import FilterComponent from "WebVisual/Components/FilterComponent";
import { constants } from "./redux";
import { updateAnalyticsAction } from "../Settings/redux";
import moment from "moment";
import ArticleDetail from "../ArticleDetail";
import Pagination from "components/Pagination/Pagination";
import ActivityIndicator from "components/ActivityIndicator";

import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";

const ArticleCard = ({ search }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");

  const state = useSelector((state) => state.VisualHomeReducer);

  const [active, setActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [offset, setOffset] = useState(0);
  const [selectPageValue, setSelectPageValue] = useState(1);

  const getArticles = () => {
    dispatch({
      type: constants.GET_ARTICLES,
      offset: offset,
    });
  };

  const addFavourite = (id, state) => {
    dispatch({
      type: constants.ADD_FAVOURITE,
      id,
      articleDetails: false,
      state,
    });
  };

  const onClickArticle = (type, item, category) => {
    dispatch(updateAnalyticsAction(type, item.id, category));
    setSelectedItem(item);
    setActive(true);
    window.scrollTo(0, 0);
  };

  const [isToggle, setIsToggle] = useState(false);
  const [isDateToggle, setIsDateToggle] = useState(false);
  const [date, setDate] = useState("");
  const [category, seCategory] = useState("");

  const onApplyFilter = (type, params) => {
    let dateQuery = date;
    let categoryQuery = category;
    if (type === "date") {
      setDate(params);
      dateQuery = params;
    } else {
      seCategory(params);
      categoryQuery = params;
    }
    dispatch({
      type: constants.DATE_FILETER,
      params: dateQuery + categoryQuery,
    });
    setIsToggle(false);
    setIsDateToggle(false);
  };

  useEffect(() => {
    if (search || source) {
      dispatch({
        type: constants.SEARCH_ARTICLES,
        search,
        source,
      });
    } else {
      getArticles();
    }
  }, [search, offset]);

  useEffect(() => {
    if (source && state.articles.length === 1) {
      onClickArticle("article", state.articles[0], "view");
    }
  }, [state.articles, source]);

  return (
    <div className="homeArticleWrapper">
      {active ? (
        <ArticleDetail item={selectedItem} setActive={setActive} />
      ) : (
        <>
          <FilterComponent
            onApplyFilter={onApplyFilter}
            isToggle={isToggle}
            setIsToggle={setIsToggle}
            isDateToggle={isDateToggle}
            setIsDateToggle={setIsDateToggle}
            listFound={`${state.count || state.articles.length || 0} Article found`}
          />
          <Row className="article-wrapper">
            {state.requesting ? (
              <ActivityIndicator requesting />
            ) : state.requesting === false && state.articles.length < 1 ? (
              <Row className="d-flex justify-content-center align-items-center m-auto">
                <p className="">No content found.</p>
              </Row>
            ) : (
              state.articles.map((item, i) => (
                <Col key={i} sm="6" md="6" lg="4" xl="3" className="mb-5 d-flex">
                  <Card body className="p-0 d-flex justify-content-between">
                    <CardHeader className="cardHeader">
                      <img src={Images.cardLogo} />
                    </CardHeader>
                    <CardTitle className="cardTitle" onClick={() => onClickArticle("article", item, "view")}>
                      <p>{item.title}</p>
                    </CardTitle>
                    <CardText>
                      <Row className="cardData">
                        <p className="categoryText">Category:</p>
                        <p className="categoryType">{item.category}</p>
                      </Row>
                      <p className="categoryDetail">{item.short_summary}</p>
                      <div className="d-flex justify-content-between dateWrraper">
                        <p className="p-0">{moment(item.date).format("MMMM, DD, YYYY")}</p>
                        <img
                          onClick={() => addFavourite(item.id, !item.is_favorite)}
                          src={item.is_favorite ? Images.favIcon : Images.likeIcon}
                          alt=""
                        />
                      </div>
                    </CardText>
                  </Card>
                </Col>
              ))
            )}
          </Row>
          {state.count > 10 && (
            <Row className="align-items-center justify-content-center">
              {state.requesting ? (
                ""
              ) : (
                <Pagination
                  totalCount={state && state.count}
                  offset={offset}
                  setOffset={setOffset}
                  selectPageValue={selectPageValue}
                  setSelectPageValue={setSelectPageValue}
                />
              )}
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default ArticleCard;
