import React from "react";
import { copyToClipboard, showSuccessToast } from "utils/functions";

import Like from "../../../Like";
import Dislike from "../../../Dislike";
import CopyConversation from "../../../CopyConversation";

import "./style.scss";
import { prepareMessageConversation } from "WebVisual/Components/Chat/hooks";
import Feedback from "../../../Feedback";
import FeedbackViewResult from "../../../FeedbackViewResult";

export const MessageActions = ({ isStreaming, message, onAddFeedback, isAdmin }) => {
  const handleCopy = () => {
    copyToClipboard(prepareMessageConversation(message));
    showSuccessToast("Chatbot response copied successfully");
  };

  const handleAddFeedback = ({ note, reaction }) => {
    onAddFeedback({ message_id: message.id, note, reaction });
  };

  if (isStreaming || message.error) {
    return null;
  }

  return (
    <div className="assistant_message_actions">
      {!isAdmin ? (
        <>
          <Dislike id={message.id} feedbackText={""} reaction={message.reaction} onAddFeedback={handleAddFeedback} />
          <Like id={message.id} feedbackText={""} reaction={message.reaction} onAddFeedback={handleAddFeedback} />
          <CopyConversation onCopy={handleCopy} />
        </>
      ) : (
        <FeedbackViewResult message={message} />
      )}
    </div>
  );
};

export default MessageActions;
