import React from "react";

import { AssistantChatMessage, UserChatMessage } from "./components";
import { MESSAGE_TYPE } from "../../constants";

import "./style.scss";
import { FollowUpQuestions } from "WebVisual/Components/ChatWidget/components";

const RenderMessage = ({ message, onAddFeedback, isStreaming, isAdmin }) => {
  if (message.type === MESSAGE_TYPE.USER) {
    return <UserChatMessage message={message} key={message.id} />;
  }

  return (
    <AssistantChatMessage
      key={message.id}
      message={message}
      onAddFeedback={onAddFeedback}
      isStreaming={isStreaming}
      isAdmin={isAdmin}
    />
  );
};

const ChatMessages = ({
  messages,
  onAddFeedback,
  onFollowUpQuestionClick,
  chatMessagesContainerRef,
  isStreaming,
  isReversed,
  isAdmin,
  isAnimation,
  followUpQuestions,
}) => {
  const [selectedQestion, setSelectedQuestion] = React.useState(null);
  const [firstQuestion, secondQuestion, thirdQuestion] = followUpQuestions || [];

  const handleFollowUpQuestionClick = (question) => {
    setSelectedQuestion(question);
    onFollowUpQuestionClick(question);
  };

  return (
    <div
      className={`chat-messages ${isReversed ? "reversed" : ""} ${isAnimation ? "animation" : ""}`}
      ref={chatMessagesContainerRef}
    >
      <div className="chat-messages-content">
        {!isAdmin && !messages.length ? (
          <div className="chat-messages-content-followups">
            <FollowUpQuestions
              selectedQestion={selectedQestion}
              firstQuestion={firstQuestion}
              secondQuestion={secondQuestion}
              thirdQuestion={thirdQuestion}
              onFollowUpQuestionClick={handleFollowUpQuestionClick}
            />
          </div>
        ) : null}
        {messages.map((m, index) => (
          <RenderMessage
            message={m}
            key={m.id}
            onAddFeedback={onAddFeedback}
            isStreaming={isStreaming && index === messages.length - 1}
            isAdmin={isAdmin}
          />
        ))}
      </div>
    </div>
  );
};

export default ChatMessages;
